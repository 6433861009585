// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
//import styles
import '../../assets/styles/containerStyles/about.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {UpdatePartnersData} from "../../redux/actions";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {history} from "../../configs/history";
import isURL from "validator/es/lib/isURL";

class AddEditPartner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fieldsData: {
                url: '',
                mediaMain: undefined,
            },
            errors: {},
            errorsTabs: [],
            isEditing:!!props.location.state,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.addEditPartner = this.addEditPartner.bind(this);

    }

    async componentDidMount() {
        const {partnersData} = this.props;
        const {isEditing} = this.state;
        const id = this.props.location.state
        if (isEditing && !id) {
            history.push('/partners');
            return;
        }

        if (isEditing) {
            const editingData = partnersData?.partners.find((partner,index) => index === Number(id));
            const initStateData = {
                url: editingData?.url || '',
                mediaMain: editingData?.mediaMain,
            };
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    getMedia(mediaArray, type) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData[type]?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set([type], mediaArray[0]?.id)
                : this.updatedDataMap.delete([type]);
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [type]: mediaArray[0],
            },
            errors: {
                ...errors,
                [type]: false
            }
        })
    }

    deleteMedia() {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain ?
                this.updatedDataMap.set("mediaMain", null) :
                this.updatedDataMap.delete("mediaMain");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: null
            }
        })
    }

    async addEditPartner() {
        const {partnersData} = this.props;
        const id = this.props.location.state
        await this.setState({
            requestLoading: true
        });
        const {mediaMain, url} = this.state.fieldsData;
        let result = true;
        const errors = {};

        if (!mediaMain) {
            errors.mediaMain = true;
            result = false;
        }

        // if (url && !isURL(url)) {
        //     errors.url = true;
        //     result = false;
        // }
        if (result) {
            if (!this.state.isEditing) {
                let data = {
                    ...this.state.fieldsData,
                    mediaMain:this.state.fieldsData.mediaMain.id
                }
                let partners = partnersData.partners.map(item => {
                    console.log(partnersData,'item')
                    return{
                        ...item,
                        mediaMain:item.mediaMain.id
                    }
                })
                const reqData = {
                    ...partnersData,
                    partners:[...partners,data]
                }

                this.props.UpdatePartnersData(reqData).then(() => {
                    this.updatedDataMap.clear();
                }).catch(() => {
                }).finally(() => {
                    this.setState({requestLoading: false});
                    history.push('/partners')
                })
            } else {
                const partData = partnersData?.partners.map((item,index) => index === Number(id) ? item = this.state.fieldsData : item);
                const reqData = {
                    ...partnersData,
                    partners:partData.map(item => {
                        return{
                            ...item,
                            mediaMain:item.mediaMain.id
                        }
                    })
                }
                this.props.UpdatePartnersData(reqData).then(() => {
                    this.updatedDataMap.clear();
                }).catch(() => {
                }).finally(() => {
                        this.setState({requestLoading: false});
                    history.push('/partners')
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
            })
        }
    }

    render() {
        const {fieldsData, errors, mediaModalOpen, requestLoading, isEditing} = this.state;

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Գործընկեր' : 'Փոփոխել Գործընկերը'}>
            <section className="slider-add-edit">

                <div className="row">
                    <div>
                        <div className="top-side">
                            <label>Նկար <span className={'required-badge'}>*</span></label>
                            <LinkButton title="Ընտրել մեդիադարանից"
                                        className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                        cb={this.toggleMediaModal}
                            />
                        </div>
                        {fieldsData?.mediaMain && <div className={'main-media'}>
                            <MediaFileCard item={fieldsData?.mediaMain}
                                           customDelete={this.deleteMedia}
                            />
                        </div>}
                    </div>
                    <div>
                    </div>

                </div>


                {/*<div className="info-wrapper">*/}
                {/*    <InputGroup inputType="input"*/}
                {/*                type="text"*/}
                {/*                label="Հղում"*/}
                {/*                placeholder="Հղում"*/}
                {/*                name="url"*/}
                {/*                value={fieldsData?.url}*/}
                {/*                initValue={this.currentData?.url}*/}
                {/*                error={errors?.url}*/}
                {/*                maxLength={100}*/}
                {/*                onChange={this.getInputValues}/>*/}
                {/*</div>*/}

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditPartner}/>
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={(mediaArray) => this.getMedia(mediaArray, 'mediaMain')}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    UpdatePartnersData
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditPartner)
