import { request, _urlMenu } from "../api";
import { MENU_CONSTS } from "../constants";
import { history } from "../../configs/history";
import { notificationSuccess } from "../../components/uiElements/Notifications";

export const GetMenus = () => {
    const requestData = {
        url: _urlMenu,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: MENU_CONSTS.GET_MENUS,
                    payload: data
                });
            })
    }
};

export const GetMenusById = (id) => {
    const requestData = {
        url: `${_urlMenu}/${id}`,
        token: true,
        method: "GET",
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: MENU_CONSTS.GET_MENU_BY_ID,
                    payload: res.data
                })
            })
    }
};

export const DeleteMenuById = id => {
    const requestData = {
        url: `${_urlMenu}/${id}`,
        token: true,
        method: "DELETE",
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: MENU_CONSTS.DELETE_MENU_BY_ID,
                    payload: id
                });
            })
    }
};

export const CreateMenu = data => {
    const requestData = {
        url: _urlMenu,
        token: true,
        method: "POST",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: MENU_CONSTS.CREATE_MENU,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Մենյու հաջողությամբ ստեղծվեց!"
                })
                history.push('/menu')

            })
    }
};

export const UpdateMenuById = (id, data, redirect = true) => {
    const requestData = {
        url: `${_urlMenu}/${id}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                redirect && notificationSuccess({
                    description: "Մենյուն հաջողությամբ փոփոխվեց!"
                })
                redirect && history.push('/menu')
            })
    }
};

export const UpdateMenuPositions = items => {
    const requestData = {
        url: _urlMenu,
        token: true,
        method: "PUT",
        data: items
    };
    return dispatch => {
        return request(requestData)
            .then(() => {
                notificationSuccess({
                    description: "Մենյուն հաջողությամբ թարմացվեց!"
                })
            })
    }
};
