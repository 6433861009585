// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Nestable from "react-nestable";

// Import components
import {NestableItem} from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {deleteItem} from "../../utils/helperFunctions";
import {
    GetFeatures,
    DeleteFeatureById
} from "../../redux/actions";
import moment from "moment";
import ItemsList from "../../components/ItemsList";


class Features extends Component {

    componentDidMount() {
        this.props.GetFeatures();
    }

    render() {
        let {featureList, requestLoading, mainLanguage} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Անվանում"],
            largeItems: [],
            actions: ["edit","delete"],
            status: false,
        };
        let itemListInfo = featureList?.map((feature) => {
            const {id} = feature;
            const trData = feature?.translations?.find(tr => tr.language === mainLanguage);
            const title = trData?.title || '';
            return {
                id,
                values: [
                    title ?? '-',
                ],
                deleteCb: deleteItem.bind(this, this.props.DeleteFeatureById, 'հատկությունը', id)
            }
        });
        return <PageWrapper pageTitle={'Հատկություններ'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/features/add"}/>

            <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                       itemListInfo={itemListInfo}
                       loading={requestLoading}
                       editLink={"/features/edit"}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetFeatures,
    DeleteFeatureById
};

export default connect(mapStateToProps, mapDispatchToProps)(Features);
