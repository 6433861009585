// Import packages
import React from "react";

// Import styles
import 'suneditor/dist/css/suneditor.min.css';
import "../../assets/styles/dataDisplay/editor.scss"

// Import components
import plugins from 'suneditor/src/plugins'
import SunEditor from 'suneditor-react';

//import utils
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import MediaSelectorModal from "../media/MediaSelectorModal";
import {toggleStateField} from "../../utils/helperFunctions";
import {generateImageMediaUrl} from "../../utils/generateMemberMediaUrl";


export class Editor extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            mediaModalOpen: false
        };
        this.innerText = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.editor = React.createRef();
        this.customPlugin = {
            // @Required @Unique
            name: 'media',
            // @Required
            display: 'command',

            // @options
            // * You can also set from the button list
            // HTML title attribute (tooltip) - default: plugin's name
            title: 'Open Media Modal',
            // HTML to be append to button (icon)
            // Recommend using the inline svg icon. - default: "<span class="se-icon-text">!</span>"
            innerHTML: "<span class='material-icons'>perm_media</span>",
            // The class of the button. - default: "se-btn"
            // "se-code-view-enabled": It is not disable when on code view mode.
            // "se-resizing-enabled": It is not disable when on using resizing module.
            buttonClass: '',

            // @Required
            add: function (core, targetElement) {
                const context = core.context;
                const rangeTag = core.util.createElement('div');
                core.util.addClass(rangeTag, '__se__format__range_custom');
                context.customCommand = {
                    targetButton: targetElement,
                    tag: rangeTag
                };
            },

            // @Override core
            // Plugins with active methods load immediately when the editor loads.
            // Called each time the selection is moved.
            active: function () {
            },

            // @Required, @Override core
            // The behavior of the "command plugin" must be defined in the "action" method.
            action: () => {
                this.toggleMediaModal()
            }
        };
        this.getMedia = this.getMedia.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onInput = this.onInput.bind(this);
    }

    getMedia(mediaArray) {
        var img = document.createElement("IMG");
        const imageUrl = generateImageMediaUrl(mediaArray[0].path);
        img.setAttribute("src", imageUrl);
        img.setAttribute("alt", "The Pulpit Rock");
        this.editor.current.editor.insertHTML(img);
    }

    onChange(value) {
        const {name, initValue} = this.props;
        // console.log('this.innerText', this.innerText)
        // console.log('value', value);
        if (this.innerText !== null && !this.innerText) {
            value = '';
        }
        this.props.onChange({value, name, haveChanges: initValue !== value});
    }

    onInput(e) {
        //console.log('onInput', e.target.innerText)
        let innerText = e.target.innerText;
        innerText = innerText && innerText.trim();
        this.innerText = innerText || "";
    }

    render() {
        const {value, error} = this.props;
        const {mediaModalOpen} = this.state;
        return <div className={`editor ${error ? 'error-editor' : ''}`}>
            <SunEditor setContents={value}
                       ref={this.editor}
                       onChange={this.onChange}
                       onInput={this.onInput}
                       setOptions={{
                           height: 'auto',
                           minHeight: "400px",
                           buttonList: [
                               ["undo", "redo"],
                               ["font", "fontSize",  "formatBlock"], //
                               ["bold", "underline", "italic", "strike"],
                               ["align"],
                               ["fontColor", "hiliteColor"],
                               ["list"],
                               ["textStyle"],
                               ["link"],
                               [{
                                   // plugin's name attribute
                                   // It must be the same as the name attribute of the plugin
                                   name: 'media',
                                   // Enter the "display" attribute value of your custom plugin.
                                   dataDisplay:  'command',

                                   // @options
                                   title: 'Open Media Modal',
                                   // HTML to be append to button (icon)
                                   // Recommend using the inline svg icon. - default: "<span class="se-icon-text">!</span>"
                                   innerHTML: "<span class='material-icons'>perm_media</span>",
                               }],
                               ["image"],
                               ["outdent"],
                               ["indent"],
                               ["subscript", "superscript"],
                               ["horizontalRule"],
                               ["lineHeight"],
                               ["table"],
                               ["blockquote"],
                               ["fullScreen"],
                               ["showBlocks"],
                               ["removeFormat"],
                               ["codeView"],
                               ["preview"],
                           ],
                           resizingBar: true,
                           charCounter: true,
                           imageFileInput: false,
                           plugins: [plugins].concat( this.customPlugin),
                       }}
            />
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </div>
    }
}
