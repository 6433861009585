// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import components
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import ItemsList from "../../components/ItemsList";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetServicesData, UpdateServicesData, DeleteServiceItem, UpdateServicesPositions} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {generateFileMediaUrl} from "../../utils/generateMemberMediaUrl";


class Languages extends Component {
    constructor() {
        super();
        this.state = {
            alertDialogOpen: false,
            alertMode: "",
            removingIndex: null
        };
    }

    componentDidMount() {
        this.props.GetServicesData();
    }

    render() {
        const {servicesData,mainLanguage} = this.props;
        let itemListHeaderInfo = {
            generalInfo: ["Անուն"],
            largeItems: [0],
            actions: ["edit"],
        };
        let itemListInfo = [];
        servicesData && !!servicesData.length && servicesData.forEach(services => {
            const trData = services?.translations?.find(tr => tr.languageId === mainLanguage);
            itemListInfo.push({
                id: services.id,
                values: [trData.title],
            })
        });

        return <PageWrapper pageTitle={'Ծառայություններ'}>
            <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                       itemListInfo={itemListInfo}
                       editLink={"/services/edit"}/>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetServicesData,
    UpdateServicesData,
    DeleteServiceItem,
    UpdateServicesPositions
};

export default connect(mapStateToProps, mapDispatchToProps)(Languages);
