// Import packages
import React from "react";
import {Tabs} from 'antd';
import {store} from "../../redux/store";

// Import utils

// Import styles
import "../../assets/styles/dataDisplay/tabs.scss";

const {TabPane} = Tabs;

export function LanguageTabs(props) {
    const {changeLanguageTab, activeTab, errorsTabs = []} = props;
    const languages = store?.getState()?.languages?.activeLanguages;

    function checkError(language) {
        return Array.isArray(errorsTabs) && errorsTabs.some(tab => tab.includes(language));
    }

    return <Tabs className="language-tabs"
                 type="card"
                 activeKey={activeTab}
                 onChange={changeLanguageTab}>
        {languages?.map((language) => {
            checkError(language);
            return <TabPane tab={<span
                className={checkError(language.id) ? 'haveError' : ''}>{language.name}</span>}
                            key={language.id}/>
        })}
    </Tabs>
}

export function CustomTabs(props) {
    const {changeTab, activeTab, tabsList} = props;
    return <Tabs className="custom-tabs"
                 type="card"
                 activeKey={activeTab}
                 onChange={changeTab}>
        {tabsList?.map(tab => {
            // console.log(tab.notSeenCount, tab.name);

            return <TabPane tab={<span className={tab.notSeenCount ? 'have-badge' : ''}>{tab.name}
                {!!tab.notSeenCount && <span className={'badge'}>{tab.notSeenCount}</span>}
            </span>}
                            key={tab.key}>
            </TabPane>
        })}
    </Tabs>
}
