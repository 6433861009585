// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import Nestable from "react-nestable";

// Import components
import { NestableItem } from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";

// Import utils
import { mapStateToProps } from "../../redux/mapStateToProps";
import {
    GetMenus,
    DeleteMenuById,
    UpdateMenuById,
    UpdateMenuPositions
} from "../../redux/actions";
import { deleteItem } from "../../utils/helperFunctions";


class Menu extends Component {
    constructor(props){
        super(props);

        this.deleteMenuItem = this.deleteMenuItem.bind(this);
        this.onPositionChange = this.onPositionChange.bind(this);
    }

    componentDidMount(){
        this.props.GetMenus();
    }

    deleteMenuItem(id){
        this.props.DeleteMenuById(id).then(() => {
            this.props.GetMenus();
        })
    }

    findParent(currentItem, id){
        if ( currentItem.children && currentItem.children.length &&
            currentItem.children.find(item => item.id === id) ) {
            let movedItemNewIndex = -1;
            const positionedItems = currentItem.children.map((item, index) => {
                item.id === id && (movedItemNewIndex = index);
                return { id: item.id, position: index }
            });
            return {
                parent: currentItem.id,
                movedItemNewIndex: movedItemNewIndex,
                positionedItems
            };
        } else {
            let resData = null;
            currentItem.children && currentItem.children.length && currentItem.children.forEach(item => {
                let result = this.findParent(item, id);
                if ( result ) {
                    resData = result;
                }
            });
            return resData;
        }
    }

    getNestableItems(list, parent = null, mainLanguage){
        const items = [];
        list && !!list.length && list.forEach((item, index) => {
            const title = item?.translations?.find(tr => tr.language === mainLanguage)?.title || '';
            items.push({
                id: item.id,
                parent: parent,
                index: index,
                title: title,
                isHidden: item.isHidden,
                link: {
                    pathname: `menu/edit/${item.id}`,
                    state:{ menu: item},
                },
                deleteCb: deleteItem.bind(this, this.deleteMenuItem, 'մենյուն', item.id),
                children: !!item.subMenu?.length ? this.getNestableItems(item.subMenu, item.id, mainLanguage) : []
            })
        });
        return items;
    }

    async onPositionChange(items, item){
        const movedMenuId = item.id;
        let movedMenuParent = null;
        items && items.forEach(item => {
            let currItemRes = this.findParent(item, movedMenuId);
            if ( currItemRes ) {
                movedMenuParent = currItemRes;
            }
        });
        if ( movedMenuParent ) {
            if ( (movedMenuParent.parent !== item.parent) || (movedMenuParent.movedItemNewIndex !== item.index) ) {
                const resData = {
                    parent: movedMenuParent.parent,
                };
                (movedMenuParent.parent !== item.parent) &&
                await this.props.UpdateMenuById(movedMenuId, resData, false);
                await this.props.UpdateMenuPositions(movedMenuParent.positionedItems);
                this.props.GetMenus();
            }
        } else {
            // first level menu was re-positioned in first level
            const newIndex = items.findIndex(m => m.id === movedMenuId);
            if ( item.parent || item.index !== newIndex ) {
                const positionedItems = items.map((item, index) => {
                    return { id: item.id, position: index }
                });
                const resData = {
                    parent: null,
                };

                item.parent && this.props.UpdateMenuById(movedMenuId, resData, false);
                await this.props.UpdateMenuPositions(positionedItems);
                this.props.GetMenus();
            }
        }
    }

    render(){
        let { menusList, mainLanguage } = this.props;
        let items = this.getNestableItems(menusList, null, mainLanguage);
        return <PageWrapper pageTitle={'Մենյու'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/menu/add"}/>
            {items && items.length !== 0 &&
            <Nestable
                items={items}
                maxDepth={1}
                onChange={this.onPositionChange}
                renderItem={NestableItem}
            />}
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetMenus,
    UpdateMenuById,
    UpdateMenuPositions,
    DeleteMenuById,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
