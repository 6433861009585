import { MEMBERS_CONSTS } from "../constants";

export const initialState = {
    membersData: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case MEMBERS_CONSTS.SET_MEMBERS_DATA:
            return {
                ...state,
                membersData: action.payload ,
            };
        case MEMBERS_CONSTS.DELETE_MEMBER_ITEM:
            return {
                ...state,
                membersData: {
                    ...state.membersData,
                    members: state.membersData.members.filter(item => item.id !== action.payload)
                } ,
            };

        default:
            return state;
    }
}
