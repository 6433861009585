// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Nestable from "react-nestable";

//import styles
import '../../assets/styles/containerStyles/about.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import PageHeader from "../../components/pageContentViews/pageHeader";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {NestableItem} from "../../components/cards/nestableItem";

// Import utils
import {
    changeStateField, deleteItem,
    getInputValues, getPatchReqData,
    getTranslatableInputValues, getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetPartnersData, UpdatePartnersData} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";

class Partners extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
            },
            errors: {},
            errorsTabs: [],
            isEditing: true,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.setPartnerData = this.setPartnerData.bind(this);
        this.deletePartners = this.deletePartners.bind(this);
        this.updatePartner = this.updatePartner.bind(this);
        this.onPositionChange = this.onPositionChange.bind(this);
    }

    async componentDidMount() {
        await this.props.GetPartnersData();
        const {partnersData} = this.props;
        this.setPartnerData(partnersData)
    }

    getNestableItems(list, parent = null) {
        const {mainLanguage} = this.props;
        const items = [];
        list && !!list.length && list.map((item, index) => {
            const trData = item?.translations?.find(tr => tr.languageId === mainLanguage);
            const title = trData?.title || '';

            items.push({
                id: index,
                index: index,
                title: title,
                withImage: true,
                path: item?.mediaMain?.path,
                link: {
                    pathname: `partners/edit/${index}`,
                    state:`${index}`,
                },
                deleteCb: deleteItem.bind(this, this.deletePartners, 'գործընկերոջը', index),
                children: []
            })
        });
        return items;
    }

    async onPositionChange(items, item) {
        const movedSliderId = item.id;
        const {partnersData} = this.props;
        const positionedItems = items.map((item, index) => {
            return {id: item.id, position: index}
        });
        if (~partnersData?.partners.findIndex(m => m.id === movedSliderId) &&
            ~positionedItems.findIndex(m => m.id === movedSliderId)) {
            // first level menu was re-positioned in first level
            await this.props.UpdatePartnersPositions(positionedItems);
            this.props.GetPartnersData();
        }
    }

    deletePartners(index){
        let partners = this.props.partnersData.partners.map(item => {
            return{
                ...item,
                mediaMain:item.mediaMain.id
            }
        })
       const reqData ={
                translations:this.props.partnersData.translations,
                partners:partners.filter((item,num) => num !== index)
        }
        this.props.UpdatePartnersData(reqData)
    }

    setPartnerData(partner) {
        const {fieldsData} = this.state;
        const translationsData = {};

        if (partner) {
            Object.keys(fieldsData.translations).forEach(key => {
                const trItem = partner.translations.find(item => item.languageId === key);
                translationsData[key] = {
                    ...fieldsData.translations[key],
                    title: trItem?.title || '',
                };
            });
        }
        const initStateData = {
            translations: translationsData,
        }
        this.currentData = JSON.parse(JSON.stringify(initStateData));
        this.setState({
            fieldsData: initStateData,
            errors: {},
            errorsTabs: [],
            requestLoading: false,
        });
    }


    async updatePartner() {
        await this.setState({
            requestLoading: true
        });
        const {translations} = this.state.fieldsData;
        const translationRequiredFields = ['title'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (result) {
            const trData = getTranslationData(translations);
            const reqData = this.getPatchReqData(trData);
            this.props.UpdatePartnersData(reqData).then((updatedData) => {
                this.updatedDataMap.clear();
                this.setPartnerData(updatedData)
            }).catch(() => {
            }).finally(() => {
                this.setState({requestLoading: false});
            });

        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {partnersData} = this.props;
        // console.log(partnersData,'Data')
        const {fieldsData, errors, languageTab, requestLoading, isEditing, errorsTabs} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        let items = this.getNestableItems(partnersData?.partners);
        return <PageWrapper pageTitle={'Գործընկերներ'}>

            <section className="about">
                <div className="info-wrapper">
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Վերնագիր"
                                placeholder="Վերնագիր"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.updatePartner}/>
                </div>

                <PageHeader linkTitle={"Ավելացնել"}
                            addingLink={"/partners/add"}/>
                {items && items.length !== 0 &&
                <Nestable
                    items={items}
                    maxDepth={1}
                    handler={()=>{}}
                    renderItem={NestableItem}
                />}
            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetPartnersData,
    UpdatePartnersData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Partners)
