// Import packages
import React, {useState, useCallback, useEffect} from 'react'
import Cropper from 'react-easy-crop';

// Import utils
import getCroppedImg from "../../../utils/cropImage";

// Import components
import {Slider, Tooltip} from "antd";
import imgCrop from '../../../assets/images/ic_crop_img.svg';
import closeIcon from '../../../assets/images/ic_close_gray.svg';
import resetIcon from '../../../assets/images/ic_reset_gray.svg';

export function ImageCropper(props) {
    const [crop, setCrop] = useState({x: 0, y: 0});
    const [zoom, setZoom] = useState(1);
    const [aspect, setAspect] = useState(4 / 3);
    const [cropping, setCropping] = useState(false);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    useEffect(() => {

        // setCroppedAreaPixels(props.editingImage.croppedAreaPixels);
        props.editingImage?.crop && setCrop(props.editingImage.crop);
        props.editingImage?.zoom && setZoom(props.editingImage.zoom);
    }, [props.editingImage]);

    const showCroppedImage = useCallback(async () => {
        setCropping(true);
        try {
            const croppedImage = await getCroppedImg(
                props.imageUrl,
                croppedAreaPixels,
            )
            //return as dataUrl

            // props.getCropImage({
            //     croppedImage:croppedImage,
            //     croppedImageFile:new File([dataURLtoBlob1(croppedImage)], "cropped-image", {type: "image/jpeg"}),
            //     imageUrl:props.imageUrl,
            //     zoom:zoom,
            //     croppedAreaPixels,
            // });

            //return as blob
            const url = URL.createObjectURL(croppedImage);
            // console.log(url);
            props.getCropImage({
                croppedPath: url,
                croppedImageFile: new File([croppedImage], "cropped.jpeg", {type: "image/jpeg"}),
                zoom,
                crop,
            });
            URL.revokeObjectURL(croppedImage)
            setZoom(1);
        } catch (e) {
          //  console.error(e)
        }
        setCropping(false);
    }, [croppedAreaPixels]);

    const onClose = useCallback(() => {
        props.closeCrop('');
    }, []);

    return (
        <div className={'crop-wrapper'}>
            <div className={`aspects-wrapper`}>
                <span className={`aspect-item ${aspect === 4 / 3 ? 'selected' : ''}`}
                      onClick={() => setAspect(4 / 3)}>
                    4 / 3
                </span>
                <span className={`aspect-item ${aspect === 5 / 4 ? 'selected' : ''}`}
                      onClick={() => setAspect(5 / 4)}>
                    5 / 4
                </span>
                <span className={`aspect-item ${aspect === 3 / 2 ? 'selected' : ''}`}
                      onClick={() => setAspect(3 / 2)}>
                   3 / 2
                </span>
                <span className={`aspect-item ${aspect === 1 ? 'selected' : ''}`}
                      onClick={() => setAspect(1)}>
                   1 / 1
                </span>
            </div>
            <div className={'crop-content'}>
                <div className={`cropping ${cropping ? 'show' : ''}`}>Cropping...</div>
                <Tooltip color="#108ee9" title={'Կտրել'} placement={'left'}>
                    <button
                        className={'crop-btn confirm-btn'}
                        onClick={showCroppedImage}>
                        <img src={imgCrop} alt="" className={'crop-icon'}/>
                    </button>
                </Tooltip>
                <Tooltip color="#108ee9" title={'Փակել'} placement={'left'}>
                    <button
                        className={'crop-btn close-btn'}
                        onClick={onClose}>
                        <img src={closeIcon} className={'crop-icon'} alt=""/>
                    </button>
                </Tooltip>
                <Tooltip color="#108ee9" title={'Չեղարկել'} placement={'left'}>
                    <button
                        className={'crop-btn reset-btn'}
                        onClick={props.resetImage}>
                        <img src={resetIcon} className={'crop-icon'} alt=""/>
                    </button>
                </Tooltip>
                <Cropper
                    image={props.imageUrl}
                    crop={crop}
                    zoom={zoom}
                    maxZoom={2}
                    showGrid={false}
                    aspect={aspect}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    classes={{
                        containerClassName: 'cropper-component',
                        cropAreaClassName: 'cropArea'
                    }}
                />

                <div className="zoom-slider">
                    <button className='zoom-btn zoom-up'
                            onClick={() => setZoom(zoom + 0.02 <= 2 ? zoom + 0.02 : zoom)}>+
                    </button>
                    <Slider
                        vertical
                        max={2}
                        min={1}
                        step={0.02}
                        value={zoom}
                        onChange={setZoom}
                    />
                    <button className='zoom-btn zoom-down'
                            onClick={() => setZoom(zoom - 0.02 >= 1 ? zoom - 0.02 : zoom)}>-
                    </button>
                </div>
            </div>
        </div>
    )
}

// function dataURLtoBlob(dataurl) {
//     var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
//         bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
//     while (n--) {
//         u8arr[n] = bstr.charCodeAt(n);
//     }
//     return new Blob([u8arr], {type: mime});
// }
