// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
//import styles
import '../../assets/styles/containerStyles/about.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField, deleteItem,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {UpdateServicesItemData, CreateServiceItem} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {history} from "../../configs/history";
import PageHeader from "../../components/pageContentViews/pageHeader";
import Nestable from "react-nestable";
import {NestableItem} from "../../components/cards/nestableItem";
import {MaterialIcon} from "../../components/utils/Icon";
import {DatePicker} from "antd";
import moment from "moment";

;

class AddEditService extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'descriptions',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaMain: undefined,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.deletePartners = this.deletePartners.bind(this);
        this.addEditService = this.addEditService.bind(this);
        this.addOptions = this.addOptions.bind(this);
        this.removeOptions = this.removeOptions.bind(this);
        this.getOptionsValues = this.getOptionsValues.bind(this);
    }

    async componentDidMount() {
        const {servicesData, id} = this.props;
        const {isEditing, fieldsData} = this.state;

        if (isEditing && !id) {
            history.push('/services');
            return;
        }

        if (isEditing) {
            const translationsData = {};
            const editingData = servicesData?.find(service => service.id === this.props.id);
            if (editingData) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = editingData.translations.find(item => item.languageId === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        title: trItem?.title || '',
                        descriptions: trItem?.descriptions || '',
                    };
                });
            }
            const initStateData = {
                translations: translationsData,
                mediaMain: editingData?.mediaMain,
            };
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }


    getOptionsValues ({ name, value, haveChanges }) {
        const { fieldsData, languageTab } = this.state;
        haveChanges
            ? this.updatedDataMap.set("descriptions" + name, value)
            : this.updatedDataMap.delete("descriptions" + name);
        let newAttributeList = [...fieldsData?.translations[languageTab]?.descriptions];
        newAttributeList[name] = value;
            this.setState({
            fieldsData: {
                ...fieldsData,
                translations: {
                    ...fieldsData.translations,
                    [languageTab]: {
                        ...fieldsData.translations[languageTab],
                       descriptions: newAttributeList
                    }
                }
            },
            errors: {
                ...this.state.errors,
                translations: {
                    ...fieldsData.translations,
                    [languageTab]: {
                        ...fieldsData.translations[languageTab],
                        [name]: false
                    }
                }
            },
        });
    }

    removeOptions (position) {
        const { fieldsData, languageTab,isEditing } = this.state;
        if (isEditing) {
            this.currentData?.translations[languageTab].descriptions ?
                 this.updatedDataMap.set('descriptions',null ) :
                 this.updatedDataMap.delete('descriptions');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                translations: {
                    ...fieldsData.translations,
                    [languageTab]: {
                        ...fieldsData.translations[languageTab],
                        descriptions:fieldsData.translations[languageTab].descriptions.filter((_, index) => position !== index)
                    }
                }
            },
        })
    }

    addOptions () {
        const { fieldsData, languageTab } = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                translations: {
                    ...fieldsData.translations,
                    [languageTab]: {
                        ...fieldsData.translations[languageTab],
                        descriptions: [...fieldsData.translations[languageTab].descriptions,'']
                    }
                }
            },
        })
    }

    getMedia(mediaArray) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('mediaMain', mediaArray[0]?.id)
                : this.updatedDataMap.delete('mediaMain');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: mediaArray[0],
            },
            errors: {
                ...errors,
                mediaMain: false
            }
        })
    }

    deleteMedia() {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain ?
                this.updatedDataMap.set("mediaMain", null) :
                this.updatedDataMap.delete("mediaMain");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: null
            }
        })
    }

    async addEditService() {
        const {id} = this.props;
        await this.setState({
            requestLoading: true
        });
        const {translations, mediaMain} = this.state.fieldsData;
        const translationRequiredFields = ['title'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (!mediaMain) {
            errors.mediaMain = true;
            result = false;
        }
        if (result) {
            if (!this.state.isEditing) {
                const reqData = {
                    mediaMain: mediaMain.id,
                    translations: getTranslationData(translations)
                };

                this.props.CreateServiceItem(reqData).finally(() => {
                    this.setState({requestLoading: false});
                })
            } else {
                const trData = getTranslationData(translations);
                const reqData = this.getPatchReqData(trData);
                this.props.UpdateServicesItemData(id, reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                        history.push('/services')
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    getNestableItems(list, slug = null) {
        const {mainLanguage,id} = this.props;
        const items = [];
        list && !!list.length && list.map((item, index) => {
            const trData = item?.translations?.find(tr => tr.languageId === mainLanguage);
            const title = trData?.title || '';

            items.push({
                id: index,
                index: index,
                title: title,
                withImage: true,
                path: item?.mediaMain.path,
                link: {
                    pathname:`/services/edit/${id}/${slug}/${index}`,
                    state:{
                        slug:`${slug}`,
                        index:`${index}`
                    },
                },
                deleteCb: deleteItem.bind(this, this.deletePartners, 'Ծառայություն', index,slug),
                children: []
            })
        });
        return items;
    }

    deletePartners(index,slug){
        const {id,servicesData} = this.props;
        const service = servicesData.find(item => item.id === id)
        let data = service[slug].map(item => {
            return{
                ...item,
                mediaMain:item.mediaMain.id
            }
        })
        let reqData = []
        if(slug === 'blocks'){
            reqData ={
                blocks:data.filter((item,num) => num !== index)
            }
        }else{
            reqData ={
                features:data.filter((item,num) => num !== index)
            }
        }
        this.props.UpdateServicesItemData(id,reqData)
    }

    render() {
        const {servicesData} = this.props;
        const {fieldsData, errors, languageTab, mediaModalOpen, requestLoading, isEditing, errorsTabs} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData?.translations[languageTab] || {};
        const editingData = servicesData && servicesData?.find(service => service.id === this.props.id);

        let blocksItems = this.getNestableItems(editingData?.blocks,'blocks');
        let featuresItems = this.getNestableItems(editingData?.features,'features');

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Ծառայություն' : 'Փոփոխել Ծառայությունը'}>
            <section className="slider-add-edit">

                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={this.deleteMedia}
                    />
                </div>}


                <div className="info-wrapper">
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Անվանում"
                                placeholder="Անվանում"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    <div className={'options'}>
                        <label className={'label'}>Նկարագրություն<span className={'required-badge'}>*</span></label>
                        {currentData?.descriptions?.length
                            && currentData?.descriptions.map((options, index) => {
                            return <div className="item-row" key={index}>
                                <InputGroup
                                    value={options}
                                    initValue={options}
                                    error={errors['descriptions' + index + languageTab]}
                                    name={index}
                                    inputType={"input"}
                                    placeholder={"Նկարագրություն"}
                                    maxLength={100}
                                    onChange={this.getOptionsValues}
                                />
                                {currentData?.descriptions?.length
                                    && <LinkButton className="bg-red delete-item-btn"
                                                   title={<MaterialIcon icon="delete"/>}
                                                   cb={() => this.removeOptions(index)}/>}

                            </div>
                        })}
                        <LinkButton title={<MaterialIcon icon={'add'}/>}
                                    cb={this.addOptions}/>
                    </div>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditService}/>
                </div>

                <label className={'list-label'}>Բաժիններ</label>
                <PageHeader linkTitle={"Ավելացնել"}
                            addingLink={`/services/${this.props.id}/blocks/add`}/>
                {blocksItems && blocksItems.length !== 0 &&
                    <Nestable
                        items={blocksItems}
                        maxDepth={1}
                        handler={()=>{}}
                        renderItem={NestableItem}
                    />}

                <label className={'list-label'}>Հատկություններ</label>
                <PageHeader linkTitle={"Ավելացնել"}
                            addingLink={`/services/${this.props.id}/features/add`}/>
                {featuresItems && featuresItems.length !== 0 &&
                    <Nestable
                        items={featuresItems}
                        maxDepth={1}
                        handler={()=>{}}
                        renderItem={NestableItem}
                    />}
            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateServiceItem,
    UpdateServicesItemData
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditService)
