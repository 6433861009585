import { WORKS_CONSTS } from "../constants";

export const initialState = {
    worksData: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case WORKS_CONSTS.SET_WORKS_DATA:
            return {
                ...state,
                worksData: action.payload ,
            };
        case WORKS_CONSTS.DELETE_WORK_ITEM:
            return {
                ...state,
                worksData: {
                    ...state.worksData,
                    works: state.worksData.works.filter(item => item.id !== action.payload)
                } ,
            };

        default:
            return state;
    }
}
