import {ACCEPT_FILE_TYPES, MAX_FILE_SIZE} from "../../../../constants/acceptedTypes";
import swal from "sweetalert";
import {FILE_TYPES} from "../../../../constants/fileTypes";

export async function uploadMedias(currentFolder) {
    //const {currentFolder} = this.props;
    const {addingFiles} = this.state;
    let formData = new FormData();
    for (let i = 0; i < addingFiles.length; i++) {
        const item = addingFiles[i];
        const uploadingFile = item.croppedImageFile || item.file
      //  console.log('uploadingFile', uploadingFile);
        formData.append('file', uploadingFile, uploadingFile.name.slice(0, 20));
    }
    currentFolder && currentFolder.id && formData.append('parent', currentFolder.id);
    currentFolder && currentFolder.path && formData.append('parentPath', currentFolder.path);
    // const pathList = currentFolder && curren0tFolder.path && currentFolder.path.split('/').slice(1);
    await this.setState({
        uploading: true
    })
    this.props.UploadFile(formData, this.onUploadProgress).then(async () => {
        await this.setState({
            uploading: false,
            uploadProgress:0,
        })
        this.props.GetMedia();
        this.cancelAddMedia();
    }).catch(() => {
        this.setState({
            uploading: false,
            uploadProgress:0,
        })
    });
}

export function onUploadProgress(progressEvent) {
    let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    // console.log('percentCompleted', percentCompleted);
    this.setState({
        uploadProgress: percentCompleted
    })
}

export function hasExtension(fileType) {
    const type = FILE_TYPES[fileType];
    if (!type) {
        return false;
    }
    return ACCEPT_FILE_TYPES.some(acceptType => acceptType === type);
}

export async function onDropFile(e) {
    const files = e.target.files;
    let errorFiles = [];
    for (let i = 0; i < files.length; i++) {
        let file = files[i];
       // console.log('file.type', file.type)
        const extensionSupported = hasExtension(file.type);
        if (!extensionSupported || file.size > MAX_FILE_SIZE) {

            errorFiles.push({
                name: file.name,
                extension: !extensionSupported,
                size: file.size > MAX_FILE_SIZE
            })
        }
    }
    if (errorFiles.length) {
        swal({
            title: "Զգուշացում!",
            text: errorFiles.map(errFile => {
                return `${errFile.name} - ${errFile.extension ? ' չթույլատրվաց ֆորմատով է, ' : ''} ${errFile.size ? ' չափսը 200mb -ից մեծ է,' : ''}`
            }).join('\n'),
            icon: "info",
            button: "Ok"
        });

    } else {
        const addingFiles = [];
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            addingFiles.push({
                imageUrl: URL.createObjectURL(file),
                file
            })
        }

        this.setState({
            addingFiles,
            filesIsChoose: true
        })
    }
}


export function getMedias(addingFiles) {
    this.setState({
        addingFiles
    });
    !addingFiles.length && this.cancelAddMedia();
}

export function onUploadClick(e) {
    e.target.value = null;
}

export function triggerFileUpload() {
    this.inputElement.current.click();
}

export function cancelAddMedia() {
    this.setState({
        filesIsChoose: false,
        addingFiles: []
    })
}
