// Import packages
import React from "react";
import {Route, Switch, Router, Redirect} from "react-router-dom";

// Import utils
import {history} from "../configs/history";
import {ProtectedRoute} from "./protectedRoute";

// Import pages
import AdminLayout from '../containers/AdminLayout';
import Login from '../containers/Login';

import Dashboard from '../containers/Dashboard';

import NotFound from '../containers/NotFound';
import Contact from '../containers/Contact';

import Moderators from '../containers/Moderators/Moderators';
import AddEditModerator from "../containers/Moderators/AddEditModerator";

import Media from "../containers/media/Media";
import EditFile from "../containers/media/EditFile";

import Languages from "../containers/languages/Languages";
import EditLanguage from "../containers/languages/EditLanguage";

import Slider from "../containers/project/Project";
import AddEditSlider from "../containers/project/AddEditProject";

import Category from "../containers/category/Category";
import AddEditCategory from "../containers/category/AddEditCategory";

import Partner from "../containers/partners/Partner";
import AddEditPartner from "../containers/partners/AddEditPartner";

import StaticTexts from "../containers/staticTexts/StaticTexts";
import CustomPages from "../containers/customPages/CustomPages";
import AddEditCustomPages from "../containers/customPages/AddEditCustomPages";
import Menu from "../containers/menu/Menu";
import AddEditMenu from "../containers/menu/AddEditMenu";
import Subscribers from "../containers/subscribers/Subscribers";
import About from "../containers/about/About";
import EditBlock from "../containers/about/EditBlock";
import Services from "../containers/services/Services";
import AddEditService from "../containers/services/AddEditService";
import Works from "../containers/works/Works";
import AddEditWork from "../containers/works/AddEditWork";
import Members from "../containers/members/Members";
import AddEditMember from "../containers/members/AddEditMember";
import Features from "../containers/features/Features";
import AddEditFeatures from "../containers/features/AddEditFeatures";
import Blog from "../containers/blog/Blog";
import AddEditBlog from "../containers/blog/AddEditBlog";
import Reservations from "../containers/reservations/Reservations";
import Blogs from "../containers/blog/Blog";
import EditServicesBlock from "../containers/services/EditServiceBlocks";
import AddServicesBlock from "../containers/services/AddServicesBlocks";
import Project from "../containers/project/Project";
import AddEditProject from "../containers/project/AddEditProject";

//import Settings from '../containers/Settings';


export default () => {

    return <Router history={history}>
        <Switch>
            <Redirect exact from="/" to="/login"/>
            <Route exact path="/login" component={Login}/>
            <AdminLayout>
                <Switch>
                    <Route exact path="/dashboard" component={Blogs}/>

                    {/*<ProtectedRoute exact path="/blog" component={Blog}/>*/}
                    <ProtectedRoute exact path="/blog/add" component={AddEditBlog}/>
                    <ProtectedRoute exact path="/blog/edit/:id" component={AddEditBlog}/>

                    <ProtectedRoute exact path="/moderators" component={Moderators}/>
                    <ProtectedRoute exact path="/moderators/add" component={AddEditModerator}/>
                    <ProtectedRoute exact path="/moderators/edit/:id" component={AddEditModerator}/>

                    <ProtectedRoute exact path="/media" component={Media}/>
                    <ProtectedRoute exact path="/media/edit/:id" component={EditFile}/>

                    <ProtectedRoute exact path="/categories" component={Category}/>
                    <ProtectedRoute exact path="/categories/add" component={AddEditCategory}/>
                    <ProtectedRoute exact path="/categories/edit/:id" component={AddEditCategory}/>

                    <ProtectedRoute exact path="/partners" component={Partner}/>
                    <ProtectedRoute exact path="/partners/add" component={AddEditPartner}/>
                    <ProtectedRoute exact path="/partners/edit/:index" component={AddEditPartner}/>

                    <ProtectedRoute exact path="/project" component={Project}/>
                    <ProtectedRoute exact path="/project/add" component={AddEditProject}/>
                    <ProtectedRoute exact path="/project/edit/:id" component={AddEditProject}/>

                    <ProtectedRoute exact path="/menu" component={Menu}/>
                    <ProtectedRoute exact path="/menu/add" component={AddEditMenu}/>
                    <ProtectedRoute exact path="/menu/edit/:index" component={AddEditMenu}/>

                    <ProtectedRoute exact path="/subscribers" component={Subscribers}/>

                    <ProtectedRoute exact path="/about" component={About}/>
                    <ProtectedRoute exact path="/about/:id" component={EditBlock}/>

                    <ProtectedRoute exact path="/services" component={Services}/>
                    <ProtectedRoute exact path="/services/edit/:id" component={AddEditService}/>
                    <ProtectedRoute exact path="/services/edit/:id/:slug/:index" component={EditServicesBlock}/>
                    <ProtectedRoute exact path="/services/:id/:slug/add" component={AddServicesBlock}/>

                    <ProtectedRoute exact path="/works" component={Works}/>
                    <ProtectedRoute exact path="/works/add" component={AddEditWork}/>
                    <ProtectedRoute exact path="/works/edit/:id" component={AddEditWork}/>

                    <ProtectedRoute exact path="/partners" component={Partner}/>
                    <ProtectedRoute exact path="/partners/add" component={AddEditPartner}/>
                    <ProtectedRoute exact path="/partners/edit/:id" component={AddEditPartner}/>

                    <ProtectedRoute exact path="/members" component={Members}/>
                    <ProtectedRoute exact path="/members/add" component={AddEditMember}/>
                    <ProtectedRoute exact path="/members/edit/:id" component={AddEditMember}/>

                    <ProtectedRoute exact path="/features" component={Features}/>
                    <ProtectedRoute exact path="/features/add" component={AddEditFeatures}/>
                    <ProtectedRoute exact path="/features/edit/:id" component={AddEditFeatures}/>

                    <ProtectedRoute exact path="/customPages" component={CustomPages}/>
                    <ProtectedRoute exact path="/customPages/add" component={AddEditCustomPages}/>
                    <ProtectedRoute exact path="/customPages/edit/:id" component={AddEditCustomPages}/>

                    <ProtectedRoute exact path="/languages" component={Languages}/>
                    <ProtectedRoute exact path="/languages/edit/:id" component={EditLanguage}/>

                    <ProtectedRoute exact path="/reservations" component={Reservations}/>

                    <ProtectedRoute exact path="/contact" component={Contact}/>
                    <ProtectedRoute exact path="/staticTexts" component={StaticTexts}/>

                    <Route exact path="/404" component={NotFound}/>
                    <Route component={NotFound}/>
                </Switch>
            </AdminLayout>
        </Switch>
    </Router>
}
