export const StaticTextFields = {
    header: {
        pageName: 'Header',
        list: [
            {
                label: "Header - home",
                key: "header_item_home"
            },
            {
                label: "Header - services",
                key: "header_item_services"
            },
            {
                label: "Header - about",
                key: "header_item_about"
            },
            {
                label: "Header - news",
                key: "header_item_news"
            },
            {
                label: "Header - staff",
                key: "header_item_staff"
            },
            {
                label: "Header - staff",
                key: "header_item_contact"
            },
        ]
    },
    homepage: {
        pageName: 'Homepage',
        list: [
            {
                label: "Homepage - main title",
                key: "homepage_main_block_title"
            },
            {
                label: "Homepage - main description",
                key: "homepage_main_block_description"
            },
            {
                label: "Homepage - partners title",
                key: "homepage_partners_block_title"
            },
            {
                label: "Homepage - services title",
                key: "homepage_services_title"
            },
            {
                label: "Homepage - services subtitle",
                key: "homepage_services_subtitle"
            },
            {
                label: "Homepage - services description",
                key: "homepage_services_description"
            },
            {
                label: "Homepage - services block1 title",
                key: "homepage_services_block1_title"
            },
            {
                label: "Homepage - services block1 description",
                key: "homepage_services_block1_description"
            },
            {
                label: "Homepage - services block2 title",
                key: "homepage_services_block2_title"
            },
            {
                label: "Homepage - services block2 description",
                key: "homepage_services_block2_description"
            },
            {
                label: "Homepage - services block3 title",
                key: "homepage_services_block3_title"
            },
            {
                label: "Homepage - services block3 description",
                key: "homepage_services_block3_description"
            },
            {
                label: "Homepage - info btn",
                key: "homepage_more_info_btn"
            },
            {
                label: "Homepage - about title",
                key: "homepage_about_block_title"
            },
            {
                label: "Homepage - about description",
                key: "homepage_about_block_description"
            },
            {
                label: "Homepage - about service title",
                key: "homepage_about_service_title"
            },
            {
                label: "Homepage - about certificate title",
                key: "homepage_about_certificate_title"
            },
            {
                label: "Homepage - about years title",
                key: "homepage_about_years_title"
            },
            {
                label: "Homepage - about employee title",
                key: "homepage_about_employee_title"
            },
            {
                label: "Homepage - about availability title",
                key: "homepage_about_availability_title"
            },
            {
                label: "Homepage - about customer title",
                key: "homepage_about_customer_title"
            },
            {
                label: "Homepage - projects title",
                key: "homepage_projects_title"
            },
            {
                label: "Homepage - projects subtitle",
                key: "homepage_projects_subtitle"
            },
            {
                label: "Homepage - projects description",
                key: "homepage_projects_description"
            },
            {
                label: "Homepage - contact title",
                key: "homepage_contact_title"
            },
            {
                label: "Homepage - contact description",
                key: "homepage_contact_description"
            },
            {
                label: "Homepage - contact placeholder email",
                key: "homepage_contact_placeholder_email"
            },
            {
                label: "Homepage - contact button send",
                key: "homepage_contact_button_send"
            },
            {
                label: "Homepage - consultation title",
                key: "homepage_consultation_title"
            },
            {
                label: "Homepage - consultation btn title",
                key: "homepage_consultation_btn_title"
            },
            {
                label: "Homepage - consultation description",
                key: "homepage_consultation_description"
            },
        ]


    },
    services:{
      pageName:"Services",
      list:[
          {
              label:"Service - messaging title",
              key:"service_messaging_main_title"
          },
          {
              label:"Service - messaging description",
              key:"service_messaging_main_description"
          },
          {
              label:"Service - shortNumber title",
              key:"service_shortNumber_main_title"
          },
          {
              label:"Service - shortNumber description",
              key:"service_shortNumber_main_description"
          },
          {
              label:"Service - callCenter title",
              key:"service_callCenter_main_title"
          },
          {
              label:"Service - callCenter description",
              key:"service_callCenter_main_description"
          },
          {
              label:"Service - messaging features title",
              key:"service_messaging_features_title"
          },
          {
              label:"Service - messaging features description",
              key:"service_messaging_features_description"
          },
          {
              label:"Service - shortNumber features title",
              key:"service_shortNumber_features_title"
          },
          {
              label:"Service - shortNumber features description",
              key:"service_shortNumber_features_description"
          },
          {
              label:"Service - callCenter features title",
              key:"service_callCenter_features_title"
          },
          {
              label:"Service - callCenter features description",
              key:"service_callCenter_features_description"
          },
      ]
    },
    footer: {
        pageName: 'Footer',
        list: [
            {
                label: "Footer - section description",
                key: "footer_section_description"
            },
            {
                label: "Footer - section sites title",
                key: "footer_section_sites_title"
            },
            {
                label: "Footer - section home",
                key: "footer_section_home"
            },
            {
                label: "Footer - section services",
                key: "footer_section_services"
            },
            {
                label: "Footer - section about",
                key: "footer_section_about"
            },
            {
                label: "Footer - section news",
                key: "footer_section_news"
            },
            {
                label: "Footer - section staff",
                key: "footer_section_staff"
            },
            {
                label: "Footer - section contact",
                key: "footer_section_contact"
            },
            {
                label: "Footer - section services title",
                key: "footer_section_services_title"
            },
            {
                label: "Footer - section correspond",
                key: "footer_section_correspond"
            },
            {
                label: "Footer - section number",
                key: "footer_section_number"
            },
            {
                label: "Footer - section call",
                key: "footer_section_call"
            },
            {
                label: "Footer - section projects title",
                key: "footer_section_projects_title"
            },
            {
                label: "Footer - section MobileID",
                key: "footer_section_MobileID"
            },
            {
                label: "Footer - section Fortune",
                key: "footer_section_Fortune"
            },
            {
                label: "Footer - section Login",
                key: "footer_section_Login"
            },
            {
                label: "Footer - section Promos",
                key: "footer_section_Promos"
            },
            {
                label: "Footer - section copyright",
                key: "footer_section_copyright_text"
            },
            {
                label: "Footer - section developed",
                key: "footer_section_developed_by_text"
            },
            {
                label: "Footer - section presentation file text",
                key: "footer_section_presentationFile_text"
            },
            {
                label: "Footer - button presentation",
                key: "footer_section_button_presentation"
            },
        ]
    },
    blog: {
        pageName: 'Blog',
        list: [
            {
                label: "Blog - Page prev",
                key: "blog_page_prev"
            },
            {
                label: "Blog - Page next",
                key: "blog_page_next"
            },
            {
                label: "Blog - Page subscribe subtitle",
                key: "blog_page_subscribe_subtitle"
            },
            {
                label: "Blog - Page subscribe title",
                key: "blog_page_subscribe_title"
            },
            {
                label: "Blog - Page subscribe description",
                key: "blog_page_subscribe_description"
            },
            {
                label: "Blog - Page last news",
                key: "blog_page_last_news_title"
            },
            {
                label: "Blog - Page all news",
                key: "blog_page_all_news_title"
            },
            {
                label: "Blog - Page data title",
                key: "blog_pageDetail_data_title"
            },
        ]
    },
    team: {
        pageName: 'Team',
        list: [
            {
                label: "Team - Page subTitle",
                key: "team_page_subTitle"
            },
            {
                label: "Team - Page title",
                key: "team_page_title"
            },
            {
                label: "Team - Page description",
                key: "team_page_description"
            },
            {
                label: "Team - feathers block1 title",
                key: "team_feathers_block1_title"
            },
            {
                label: "Team - feathers block2 title",
                key: "team_feathers_block2_title"
            },
            {
                label: "Team - feathers block3 title",
                key: "team_feathers_block3_title"
            },
            {
                label: "Team - feathers block1 description",
                key: "team_feathers_block1_description"
            },
            {
                label: "Team - feathers block2 description",
                key: "team_feathers_block2_description"
            },
            {
                label: "Team - feathers block3 description",
                key: "team_feathers_block3_description"
            },
        ]
    },
    contacts: {
        pageName: 'Contacts',
        list: [
            {
                label: "Contacts - Page placeholder name",
                key: "contacts_page_placeholder_name"
            },
            {
                label: "Contacts - Page label name",
                key: "contacts_page_label_name"
            },
            {
                label: "Contacts - Page placeholder lastName",
                key: "contacts_page_placeholder_lastName"
            },
            {
                label: "Contacts - Page label lastName",
                key: "contacts_page_label_lastName"
            },
            {
                label: "Contacts - Page placeholder email",
                key: "contacts_page_placeholder_email"
            },
            {
                label: "Contacts - Page label email",
                key: "contacts_page_label_email"
            },
            {
                label: "Contacts - Page placeholder phoneNumber",
                key: "contacts_page_placeholder_phoneNumber"
            },
            {
                label: "Contacts - Page label phoneNumber",
                key: "contacts_page_label_phoneNumber"
            },
            {
                label: "Contacts - Page placeholder message",
                key: "contacts_page_placeholder_message"
            },
            {
                label: "Contacts - Page label message",
                key: "contacts_page_label_message"
            },
            {
                label: "Contacts - Page btn send",
                key: "contacts_page_btn_send"
            },
            {
                label: "Contacts - Page services",
                key: "contacts_page_services"
            },
            {
                label: "Contacts - Page services correspondence",
                key: "contacts_page_services_correspondence"
            },
            {
                label: "Contacts - Page services call",
                key: "contacts_page_services_call"
            },
            {
                label: "Contacts - Page services number",
                key: "contacts_page_services_number"
            },
            {
                label: "Contacts - Page services other",
                key: "contacts_page_services_other"
            },
            {
                label: "Contacts - Page bloke1 title",
                key: "contacts_page_info_bloke1_title"
            },
            {
                label: "Contacts - Page bloke1 description",
                key: "contacts_page_info_bloke1_description"
            },
            {
                label: "Contacts - Page bloke2 title",
                key: "contacts_page_info_bloke2_title"
            },
            {
                label: "Contacts - Page bloke2 description",
                key: "contacts_page_info_bloke2_description"
            },
            {
                label: "Contacts - Page bloke3 title",
                key: "contacts_page_info_bloke3_title"
            },
            {
                label: "Contacts - Page bloke3 description",
                key: "contacts_page_info_bloke3_description"
            },
        ]
    },
}
