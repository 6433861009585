import {store} from "../redux/store";

export const validateTranslations = (validationData,
                                     requiredFields,
                                     onlyMainRequired) => {
    let result = true;
    const errorsTabs = [];
    const errors = {};
    const activeLanguages = store?.getState()?.languages?.activeLanguages;
    const mainLanguage = store?.getState()?.languages?.mainLanguage;
    activeLanguages && activeLanguages.forEach(lg => {
        const trData = validationData?.[lg.id] || {};
        const isMain = lg.id === mainLanguage;
        let translationIsNotEmpty = false;
        const errorFields = [];

        requiredFields.forEach(key => {
            if (!trData[key] || trData[key] === "") {
                // console.groupCollapsed(` %c Translation`, "color:red");
                // console.log( trData);
                // console.log( key);
                // console.log( lg);
                // console.groupEnd();
                errorFields.push(key + lg.id);
            } else {
                translationIsNotEmpty = true;
            }
        });
        if (errorFields.length && (isMain || !onlyMainRequired || translationIsNotEmpty)) {
            errorFields.forEach(field => errors[field] = true);
            errorsTabs.push(lg.id);
            result = false;
        }
    });
    return {
        result,
        errorsTabs,
        errors,
    };
};
