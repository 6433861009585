import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Dropdown} from 'antd';

//import utils
import '../../assets/styles/media/add-folder-card.scss';
import folder from '../../assets/images/file-manager/new_folder.svg';

//import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {GetMedia, AddFolder} from "../../redux/actions";
import AddFolderCard from "./AddFolderCard";


function AddMediaFolder(props) {
    const [addFolderCardOpen, setAddFolderCardOpen] = useState(false);

    function toggleAddFolderCard() {
        setAddFolderCardOpen(!addFolderCardOpen)
    }


    function addFolder(folderName) {
        const {folderList} = props;
        const currentFolder = folderList && !!folderList.length && folderList[folderList.length - 1];
        const pathList = currentFolder && currentFolder.path && currentFolder.path.split('/').slice(1);
        const reqData = {
            name: folderName,
        };
        (currentFolder && currentFolder.id) && (reqData.parent = currentFolder.id);
        (currentFolder && currentFolder.path) && (reqData.parentPath = currentFolder.path);
        //console.log(folderName)
        return props.AddFolder(reqData, pathList)
            .then(() => {
                props.GetMedia();
            })
    }

    return <Dropdown placement="bottomLeft"
                     trigger={["click"]}
                     className={"add-folder-btn"}
                     overlayClassName={`add-folder-dropdown ${props.folderDropDownClassName ? props.folderDropDownClassName : ''}`}
                     onVisibleChange={toggleAddFolderCard}
                     visible={addFolderCardOpen}
                     overlay={
                         <AddFolderCard closeModal={toggleAddFolderCard}
                                        clearCardData={addFolderCardOpen}
                                        addFolder={addFolder}/>
                     }>
        <div><img src={folder} alt=""/>Ավելացնել Թղթապանակ</div>
    </Dropdown>
}


const mapDispatchToProps = {
    AddFolder,
    GetMedia,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddMediaFolder);
