// Import packages
import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch } from "antd";

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import { InputGroup } from "../../components/uiElements/inputGroup";
import { LinkButton } from "../../components/buttons/buttons";
import { LanguageTabs } from "../../components/uiElements/Tabs";

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
} from "../../utils/helperFunctions";
import { mapStateToProps } from "../../redux/mapStateToProps";
import { CreateMenu, GetMenusById, UpdateMenuById } from "../../redux/actions";
import { validateTranslations } from "../../utils/validateTranslations";


class AddEditMenu extends Component {
    constructor(props){
        super(props);
        this.translationsFields = [
            'title',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                url: '',
                parent: undefined,
                pageId: undefined,
                isHidden: false,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.addEditMenu = this.addEditMenu.bind(this);
    }

    async componentDidMount(){
        const { mainLanguage, id, menusList, location } = this.props;
        const { isEditing } = this.state;
        const translationsData = {};

        this.menusOptions = [];
        Array.isArray(this.props.menusList) && this.props.menusList.forEach(item => {
            const title = item?.translations?.find(tr => tr.language === mainLanguage)?.title || '';
            this.menusOptions.push({
                name: title,
                id: item?.id,
            })
            Array.isArray(item?.subMenu) && item.subMenu.forEach(sub => {
                const subtitle = sub?.translations?.find(tr => tr.language === mainLanguage)?.title || '';
                this.menusOptions.push({
                    name: subtitle,
                    id: sub?.id,
                })
            })
        });
        this.customPagesOptions = [];
        Array.isArray(this.props.customPagesList) && this.props.customPagesList.forEach(item => {
            const title = item?.translations?.find(tr => tr.language === mainLanguage)?.title || '';
            this.customPagesOptions.push({
                name: title,
                id: item?.id,
            })
        });

        if ( isEditing ) {
            const editingData = location?.state?.menu
            this.menusOptions = this.menusOptions.filter(item => item.id !== this.props.id);
            if ( editingData ) {

                editingData.translations.forEach(item => {
                    translationsData[item.language] = {
                        ...translationsData[item.language],
                        title: item.title || '',
                    };
                });

            }
            const initStateData = {
                translations: translationsData,
                url: editingData?.url,
                parent: editingData?.parent,
                pageId: editingData?.pageId,
                isHidden: editingData?.isHidden,
            };
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                ...this.state,
                fieldsData: initStateData
            });

        } else {
            //for menu options
            this.forceUpdate()
        }
    }


    addEditMenu(){
        this.setState({
            requestLoading: true
        });
        const { translations, url, isHidden, parent, pageId } = this.state.fieldsData;
        const translationRequiredFields = ['title']
        const validationTr = validateTranslations(translations, translationRequiredFields)
        let result = validationTr.result;
        const errors = { ...validationTr.errors };
        if ( !pageId ) {
            errors.pageId = true
        }
        if ( result ) {
            const trData = getTranslationData(translations)
            if ( !this.state.isEditing ) {
                const reqData = {
                    isHidden,
                    translations: trData,
                };
                if ( parent ) reqData.parent = parent;
                if ( pageId ) reqData.pageId = pageId;
                if ( url ) reqData.url = url;
                this.props.CreateMenu(reqData)
                    .finally(() => {
                        this.setState({ requestLoading: false });
                    })
            } else {
                const reqData = this.getPatchReqData(trData);
                this.props.UpdateMenuById(this.props.id, reqData)
                    .finally(() => {
                        this.setState({ requestLoading: false });
                    });
            }

        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render(){
        const { fieldsData, errors, languageTab, requestLoading, isEditing, errorsTabs } = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Մենյու' : 'Փոփոխել Մենյուն'}>
            <section className="general-add-edit">
                <div className="fixed-top-right">
                    <label>{!fieldsData.isHidden ? "Ակտիվ " : "Ոչ ակտիվ "}</label>
                    <Switch checked={!fieldsData.isHidden}
                            onChange={(checked) => this.getInputValues({
                                name: "isHidden",
                                value: !checked,
                                haveChanges: this.currentData?.isHidden !== !checked
                            })}/>
                </div>
                {/* <InputGroup inputType="wrapper"
                            label={"Ծնող Մենյու"}>
                    <InputGroup inputType="selectCustom"
                                name="parent"
                                value={fieldsData.parent}
                                initValue={this.currentData?.parent}
                                withClear={true}
                                placeholder={'Ծնող Մենյու'}
                                onChange={this.getInputValues}
                                options={this.menusOptions}
                    />
                </InputGroup>*/}

                <InputGroup inputType="selectCustom"
                            name="pageId"
                            label={"Դինամիկ էջ"}
                            value={fieldsData.pageId}
                            initValue={this.currentData?.pageId}
                    // withClear={true}
                            required={true}
                            error={errors['pageId']}
                            placeholder={'Դինամիկ էջ'}
                            onChange={this.getInputValues}
                            options={this.customPagesOptions}
                />
                {/*<InputGroup inputType="input"
                            type="text"
                            label="Հղում"
                            placeholder="Հղում"
                            name="url"
                            value={fieldsData.url}
                            initValue={this.currentData?.url}
                            error={errors['url']}
                            maxLength={500}
                            required={true}
                            onChange={this.getInputValues}/>*/}
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}
                />
                <div className="menu-left-part">
                    <InputGroup inputType="input"
                                type="text"
                                label="Անվանում"
                                placeholder="Անվանում"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>

                </div>
                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditMenu}/>
                </div>

            </section>
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateMenu,
    GetMenusById,
    UpdateMenuById
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditMenu)
