// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";

// Import styles
import "../assets/styles/containerStyles/contact.scss";

// Import components
import {InputGroup} from "../components/uiElements/inputGroup";
import PageWrapper from '../components/pageContentViews/pageWrapper'
import {LinkButton} from "../components/buttons/buttons";
import {LanguageTabs} from "../components/uiElements/Tabs";

// Import utils
import {mapStateToProps} from "../redux/mapStateToProps";
import {GetContact, UpdateContact} from "../redux/actions";
import {ContactAddingList} from "../constants/contactAddingList";
import {
    changeStateField,
    getInputValues,
    initTranslations,
    getTranslatableInputValues, getTranslationData, toggleStateField
} from "../utils/helperFunctions";
import {validateTranslations} from "../utils/validateTranslations";
import {Editor} from "../components/uiElements/Editor";
import {MediaFileCard} from "../components/media/MediaFileCard";
import MediaSelectorModal from "../components/media/MediaSelectorModal";
import {ACCEPT_IMAGE_TYPES} from "../constants/acceptedTypes";

export class Contact extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'address1',
            'address2',
            'title',
            'description',
            'presentationFile',
            'privacyPolicy'
        ]
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                email: "",
                viber: "",
                whatsapp: "",
                telegram: "",
                phoneNumber: "",
                facebookUrl: "",
                linkedinUrl: "",
                instagramUrl: "",
                twitterUrl: "",
                latitude: 0,
                longitude: 0,
            },
            isEditing: true,
            errorsTabs: [],
            errors: {},
            languageTab: props.mainLanguage,
            mediaModalOpen: false,
        };
        this.errorsSet = new Set();
        this.errorsTabs = new Set();
        this.updatedDataMap = new Map();
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.togglePresentationFileModal = toggleStateField.bind(this, 'presentationFileModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getTranslatableInputValues = getTranslatableInputValues.bind(this);
        this.getPresentationFile = this.getPresentationFile.bind(this);
        this.validateFields = this.validateFields.bind(this);
        this.updateContact = this.updateContact.bind(this);
    }


    async componentDidMount() {
        !this.props?.contactData?.id && await this.props.GetContact();
        const {contactData} = this.props;
        const {fieldsData} = this.state;

        const translationsData = {};
        Object.keys(fieldsData.translations).forEach(key => {
            const trItem = contactData?.translations?.find(item => item.languageId === key);
            translationsData[key] = {
                ...fieldsData.translations[key],
                address1: trItem?.address1 || '',
                address2: trItem?.address2 || '',
                title: trItem?.title || '',
                description: trItem?.description || '',
                privacyPolicy: trItem?.privacyPolicy || '',
                presentationFile: trItem?.presentationFile || '',
            };
        });
        contactData && this.setState({
            fieldsData: {
                email: contactData.email || "",
                viber: contactData.viber || "",
                whatsapp: contactData.whatsapp || "",
                telegram: contactData.telegram || "",
                phoneNumber: contactData.phoneNumber || "",
                facebookUrl: contactData.facebookUrl || "",
                linkedinUrl: contactData.linkedinUrl || "",
                instagramUrl: contactData.instagramUrl || "",
                twitterUrl: contactData.twitterUrl || "",
                latitude: contactData?.location?.latitude || "",
                longitude: contactData?.location?.longitude || "",
                translations: translationsData
            },
        });
    }

    getPresentationFile(pdf) {
        const {fieldsData,languageTab} = this.state;
        this.setState({
            fieldsData: {
                ...fieldsData,
                translations: {
                    ...fieldsData.translations,
                    [languageTab]: {
                        ...fieldsData.translations[languageTab],
                        presentationFile: pdf[0],
                    }
                }
            },
        })
    }

    validateFields(constacData) {
        const translationRequiredFields = ['address1','address2','title', 'description', 'privacyPolicy',];
        const validationTr = validateTranslations(constacData.translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};
        const phoneNumberRegExp = /^[+]?[0-9]{9,20}$/;
        if (constacData?.phoneNumber?.length && !phoneNumberRegExp.test(constacData.phoneNumber)) {
            errors.phoneNumber = true;
            result = false;
        }
        if (!result) {
            this.setState({
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
        return result;
    }

    updateContact() {
        const {fieldsData} = this.state;
        const {translations} = fieldsData;
        if (this.validateFields(fieldsData)) {
            let TR_data = getTranslationData(translations);

            let reqData = {
                email: fieldsData.email,
                viber: fieldsData.viber,
                whatsapp: fieldsData.whatsapp,
                telegram: fieldsData.telegram,
                phoneNumber: fieldsData.phoneNumber,
                facebookUrl: fieldsData.facebookUrl,
                linkedinUrl: fieldsData.linkedinUrl,
                instagramUrl: fieldsData.instagramUrl,
                twitterUrl: fieldsData.twitterUrl,
                location: {
                    latitude: fieldsData?.latitude,
                    longitude: fieldsData?.longitude,
                },
                translations: TR_data,
            };
            //console.log('reqData', reqData);
            //
            this.props.UpdateContact(reqData).then(() => {
                this.updatedDataMap.clear();
                this.setState({
                    errors: {},
                    errorsTabs: []
                })
            })
        }
    }

    render() {
        const {requestLoading, contactData: contact} = this.props;
        const {fieldsData, languageTab, errors, errorsTabs,presentationFileModalOpen} = this.state;

        const {translations} = fieldsData;
        const initTranslations = contact?.translations?.find(lg => lg.languageId === languageTab) || {};

        const trData = translations[languageTab] || {};
        return <PageWrapper pageTitle={'Կապ'}>
            <section className="contact">

                <div className="left-part">
                    <InputGroup inputType="input"
                                type="text"
                                label="Լայնություն"
                                placeholder="Լայնություն"
                                regExp={/^[0-9]*(\.)?[0-9]*$/}
                                maxValue={90}
                                minValue={-90}
                                name="latitude"
                                required={true}
                                value={fieldsData?.latitude}
                                error={errors['latitude']}
                                onChange={this.getInputValues}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Երկայնություն"
                                placeholder="Երկայնություն"
                                regExp={/^[0-9]*(\.)?[0-9]*$/}
                                maxValue={180}
                                minValue={-180}
                                name="longitude"
                                required={true}
                                value={fieldsData?.longitude}
                                error={errors['longitude']}
                                onChange={this.getInputValues}/>
                </div>
                <div className="info-adding-fields">
                    {ContactAddingList.slice(0, 5).map((item, index) => {
                        return <InputGroup key={index}
                                           {...item}
                                           error={errors[item.name]}
                                           value={fieldsData?.[item.name]}
                                           initValue={contact?.[item.name]}
                                           onChange={this.getInputValues}/>
                    })}
                    <div className="social-links">
                        <label>Սոցիալական հղումներ</label>
                        {ContactAddingList.slice(5).map((item, index) => {
                            return <InputGroup key={index}
                                               {...item}
                                               error={errors[item.name]}
                                               value={fieldsData?.[item.name]}
                                               initValue={contact?.[item.name]}
                                               onChange={this.getInputValues}/>
                        })}
                    </div>
                </div>
                <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                              errorsTabs={errorsTabs}
                              activeTab={languageTab}/>
                <div className={'label-and-action'}>
                    <label>Կցել Presentation File</label>
                    <LinkButton className={"media-select-btn bg-white"}
                                title={"Ընտրել PDF ֆայլ"}
                                cb={this.togglePresentationFileModal}/>
                </div>
                <div className={'contract-wrapper'}>
                    <div className={'wrapper'}>
                        <span>Անվանում՝ {fieldsData.translations[languageTab]?.presentationFile?.name || initTranslations?.presentationFile?.name}</span>
                    </div>
                </div>
                <InputGroup inputType="input"
                            type="text"
                            label={'Հասցե'}
                            name="address1"
                            maxLength={100}
                            value={trData?.address1}
                            required={true}
                            initValue={initTranslations?.address1}
                            error={errors['address1' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>
                <InputGroup inputType="input"
                            type="text"
                            label={'Հասցե'}
                            name="address2"
                            maxLength={100}
                            value={trData?.address2}
                            required={true}
                            initValue={initTranslations?.address2}
                            error={errors['address2' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>
                <InputGroup inputType="input"
                            type="text"
                            label={'Վերնագիր'}
                            name="title"
                            maxLength={100}
                            value={trData?.title}
                            required={true}
                            initValue={initTranslations?.title}
                            error={errors['title' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>
                <InputGroup inputType="input"
                            type="text"
                            label={'Ենթավերնագիր'}
                            name="description"
                            maxLength={100}
                            value={trData?.description}
                            required={true}
                            initValue={initTranslations?.description}
                            error={errors['description' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>
                <div className="editor-wrapper">
                    <label>Գաղտնիության քաղաքականություն <span className={'required-badge'}>*</span></label>
                    <Editor value={trData?.privacyPolicy || ''}
                            name={'privacyPolicy'}
                            initValue={initTranslations?.privacyPolicy}
                            error={errors['privacyPolicy' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>
                </div>
                {/*                <InputGroup inputType="textarea"
                            label={<>Գաղտնիության քաղաքականություն<span
                                className="field-required">*</span></>}
                            name="privacyPolicy"
                            value={trData.privacyPolicy}
                            initValue={initTranslations?.privacyPolicy}
                            error={errors['privacyPolicy' + languageTab]}
                            onChange={this.getTranslatableInputValues}/>*/}
                <div className="flex-wrapper-right">
                    <LinkButton title="Պահպանել"
                                // disabled={!this.updatedDataMap.size}
                                loading={requestLoading}
                                cb={this.updateContact}/>
                </div>
            </section>
            <MediaSelectorModal
                isOpen={!!presentationFileModalOpen}
                acceptTypes={['pdf']}
                getMedia={this.getPresentationFile}
                appendMedias={fieldsData.translations[languageTab]?.presentationFile}
                closeModal={this.togglePresentationFileModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    GetContact,
    UpdateContact,
};

export default connect(mapStateToProps, mapDispatchToProps)(Contact);


/*
*
* {
  "name": "shat-admin",
  "version": "0.1.0",
  "private": true,
  "dependencies": {
    "@testing-library/jest-dom": "^5.1.1",
    "@testing-library/react": "^9.5.0",
    "@testing-library/user-event": "^10.0.0",
    "antd": "^4.8.6",
    "axios": "^0.19.2",
    "babel-plugin-import": "^1.13.0",
    "customize-cra": "^0.9.1",
    "history": "^4.10.1",
    "less": "^3.11.1",
    "less-loader": "5.0.0",
    "material-icons": "^0.3.1",
    "moment": "^2.24.0",
    "node-sass": "^4.14.1",
    "qrcode.react": "^1.0.0",
    "query-string": "^6.11.1",
    "react": "^16.13.1",
    "react-app-rewired": "^2.1.5",
    "react-color": "^2.18.0",
    "react-copy-to-clipboard": "^5.0.2",
    "react-data-export": "^0.6.0",
    "react-dom": "^16.13.1",
    "react-easy-crop": "^3.0.1",
    "react-infinite-scroller": "^1.2.4",
    "react-modal": "^3.11.2",
    "react-nestable": "^1.3.0",
    "react-redux": "^7.2.0",
    "react-router-dom": "^5.1.2",
    "react-scripts": "^3.4.1",
    "redux": "^4.0.5",
    "redux-persist": "^6.0.0",
    "redux-thunk": "^2.3.0",
    "suneditor-react": "^2.11.1",
    "sweetalert": "^2.1.2",
    "validator": "^12.2.0",
    "xlsx": "^0.16.0"
  },
  "scripts": {
    "start": "set PORT=3501 && react-app-rewired start",
    "build": "react-app-rewired build",
    "test": "react-scripts test",
    "eject": "react-scripts eject"
  },
  "eslintConfig": {
    "extends": "react-app"
  },
  "browserslist": {
    "production": [
      ">0.2%",
      "not dead",
      "not op_mini all"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  },
  "devDependencies": {
    "redux-devtools-extension": "^2.13.8"
  }
}

* */