import React, {useEffect} from "react";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import ItemsList from "../../components/ItemsList";
import {connect} from "react-redux";
import {getPropsFromState} from "../../redux/mapStateToProps";
import {DeleteCallDataById, getCallData} from "../../redux/actions";
import moment from "moment";
import {deleteItem} from "../../utils/helperFunctions";

function Reservations(props) {
    const {callModelItems} = props

    const SELECT_SERVICE = {
        finance:{
            key:"FINANCE",
            text:"Ֆինանսական"
        },
        business:{
            key:"BUSINESS",
            text:"Բիզնես խորհրդատվություն"
        },
        accounting:{
            key:"ACCOUNTING",
            text:"Հաշվապահություն"
        },
        jurisprudence:{
            key:"LAW",
            text:"Իրավաբանություն"
        },
    }

    useEffect(() => {
      props.getCallData()
    },[])

    let itemListHeaderInfo = {
        generalInfo: ["Անուն Ազգանուն","Հեռախոս","Էլ․ հասցե","Ծառայության տեսակ", "Ամսաթիվ"],
        actions: ["delete"],
    };
    let itemListInfo = callModelItems?.map((items) => {
        let serviceText = ""
        Object.values(SELECT_SERVICE).map(item => {
            if(item.key === items.service){
                serviceText = item.text
            }
            return serviceText
        })

        const {id,fullName,email, createdAt,phoneNumber,time,callNearestPeriod} = items;
        return {
            id,
            values: [
                fullName,
                phoneNumber,
                email ?? '-',
                serviceText,
               callNearestPeriod ?  moment(createdAt).format('DD/MM/YYYY') : moment(time).format('DD/MM/YYYY HH:mm')
            ],
            deleteCb: deleteItem.bind(this, props.DeleteCallDataById,'հայտը',id)
        }
    });
    return <PageWrapper pageTitle={'Հայտեր'}>
        <ItemsList itemListHeaderInfo={itemListHeaderInfo}
                   itemListInfo={itemListInfo}
                   infiniteScroll={true}/>
    </PageWrapper>
}

const mapDispatchToProps = {
    getCallData,
    DeleteCallDataById,
};
const mapStateToProps = (state) => {
    return getPropsFromState(state, [
    'callModelItems'
    ])
}
export default connect(mapStateToProps, mapDispatchToProps)(Reservations);