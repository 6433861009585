export const ContactAddingList = [
    {
        label: "Էլ․ հասցե",
        inputType: "input",
        type: "text",
        placeholder: "Էլ․ հասցե",
        name: "email",
        maxLength: 500
    },
    {
        label: "Viber",
        inputType: "input",
        type: "text",
        placeholder: "viber",
        name: "viber",
        maxLength: 500
    },
    {
        label: "Whatsapp",
        inputType: "input",
        type: "text",
        placeholder: "whatsapp",
        name: "whatsapp",
        maxLength: 500
    },
    {
        label: "Telegram",
        inputType: "input",
        type: "text",
        placeholder: "telegram",
        name: "telegram",
        maxLength: 500
    },
    {
        label: "Հեռախոս",
        inputType: "input",
        type: "text",
        regExp:/^[+\d]\d*$/,
        placeholder: "Հեռախոս",
        name: "phoneNumber",
        maxLength: 20
    },
    {
        inputType: "input",
        type: "text",
        placeholder: "Facebook",
        name: "facebookUrl",
        maxLength: 1000
    },
    {
        inputType: "input",
        type: "text",
        placeholder: "Linkedin",
        name: "linkedinUrl",
        maxLength: 1000
    },
    {
        inputType: "input",
        type: "text",
        placeholder: "Instagram",
        name: "instagramUrl",
        maxLength: 1000
    },
    {
        inputType: "input",
        type: "text",
        placeholder: "Twitter",
        name: "twitterUrl",
        maxLength: 1000
    }
];
