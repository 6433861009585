import {request, _urlModerator} from "../api";
import {MODERATOR_CONSTS, UTIL_CONSTS} from "../constants";
import swal from "sweetalert";
import {history} from "../../configs/history";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetModerators = () => {
    const requestData = {
        url: _urlModerator,
        token: true,
        method: "GET",
    };

    return dispatch => {
        dispatch({
            type: UTIL_CONSTS.START_LOADING,
        });
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: MODERATOR_CONSTS.GET_MODERATORS,
                    payload: data
                });
                // console.log(data);
            }).finally(() => {
                dispatch({
                    type: UTIL_CONSTS.END_LOADING,
                })
            })
    }
};


export const AddModerator = (moderator_data) => {
    const requestData = {
        url: _urlModerator,
        token: true,
        method: "POST",
        data: moderator_data
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: MODERATOR_CONSTS.ADD_MODERATOR,
                    payload: data
                });
                notificationSuccess({
                    description: "Մոդերատորը հաջողությամբ ստեղծվեց!"
                })
                history.push('/moderators')
            })
    }
};

export const EditModerator = (id, moderator_data) => {
    const requestData = {
        url: `${_urlModerator}/${id}`,
        token: true,
        method: "PATCH",
        data: moderator_data
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: MODERATOR_CONSTS.EDIT_MODERATOR,
                    payload: data
                });
                notificationSuccess({
                    description: "Մոդերատորը հաջողությամբ փոփոխվեց!"
                })
                history.push('/moderators')
            })
    }
};

export const DeleteModerator = (id) => {
    const requestData = {
        url: `${_urlModerator}/${id}`,
        token: true,
        method: "DELETE",
    };

    return dispatch => {
        return request(requestData)
            .then(({data}) => {
                dispatch({
                    type: MODERATOR_CONSTS.DELETE_MODERATOR,
                    payload: id
                });
                notificationSuccess({
                    description: "Մոդերատորը հաջողությամբ ջնջվեց!"
                })
            })
    }
};
