// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
//import styles
import '../../assets/styles/containerStyles/about.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {UpdateAboutData, UpdateAboutItemData} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {history} from "../../configs/history";
import {Editor} from "../../components/uiElements/Editor";

class EditBlock extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaMain: undefined,
            },
            errors: {},
            errorsTabs: [],
            isEditing: true,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.updateItem = this.updateItem.bind(this);

    }

    async componentDidMount() {
        const {about, id} = this.props;

        if (!id || !about) {
            history.push('/about');
            return;
        }

        const {fieldsData} = this.state;
        const translationsData = {};
        const editingData = about?.blocks.find((item ,index) => index === Number(id));
        if (editingData) {
            Object.keys(fieldsData.translations).forEach(key => {
                const trItem = editingData.translations.find(item => item.languageId === key);
                translationsData[key] = {
                    ...fieldsData.translations[key],
                    title: trItem?.title || '',
                    description: trItem?.description || '',
                };
            });
        }

        const initStateData = {
            translations: translationsData,
            mediaMain: editingData?.mediaMain,
        };
        this.currentData = JSON.parse(JSON.stringify(initStateData));
        this.setState({
            fieldsData: initStateData,
        });
    }

    getMedia(mediaArray) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('mediaMain', mediaArray[0]?.id)
                : this.updatedDataMap.delete('mediaMain');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: mediaArray[0],
            },
            errors: {
                ...errors,
                mediaMain: false
            }
        })
    }

    async updateItem() {
        const {id,about} = this.props;
        console.log(about,'about')
        await this.setState({
            requestLoading: true
        });
        const {translations, mediaMain} = this.state.fieldsData;
        const translationRequiredFields = ['title'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (!mediaMain) {
            errors.mediaMain = true;
            result = false;
        }
        if (result) {
            const trData = getTranslationData(translations);
            this.state.fieldsData.translations = trData
            const blockData = about?.blocks.map((item,index) => index === Number(id) ? item = this.state.fieldsData : item);

            const reqData = {
                ...about,
              media:about?.media?.id,
              blocks:blockData.map(item => {
                  return{
                      ...item,
                      mediaMain:item.mediaMain.id
                  }
              })
            }
            this.props.UpdateAboutData(reqData).then(() => {
                this.updatedDataMap.clear();
            }).catch(() => {
            }).finally(() => {
                this.setState({ requestLoading: false });
                history.push('/about')
            });
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {fieldsData, errors, languageTab, mediaModalOpen, requestLoading, isEditing, errorsTabs} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Բաժին' : 'Փոփոխել Բաժինը'}>
            <section className="slider-add-edit">

                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={this.deleteMedia}
                    />
                </div>}


                <div className="info-wrapper">
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Անվանում"
                                placeholder="Անվանում"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                    {
                        this.props.id === 'block-first'
                            ? <InputGroup inputType="textarea"
                                          type="text"
                                          label="Նկարագրություն"
                                          placeholder="Նկարագրություն"
                                          name="description"
                                          value={currentData.description}
                                          initValue={initData.description}
                                          error={errors['description' + languageTab]}
                                          maxLength={1000}
                                          required={true}
                                          onChange={this.getTranslationValues}/>
                            : <div className={'editor-wrapper'}>
                                <label>Նկարագրություն</label>
                                <Editor value={currentData.description || ""}
                                        name={'description'}
                                        initValue={initData.description}
                                        error={errors['description' + languageTab]}
                                        onChange={this.getTranslationValues}/>
                            </div>
                    }
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.updateItem}/>
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    UpdateAboutItemData,
    UpdateAboutData
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBlock)
