import {MEDIA_TYPES} from "../../../constants/constTypes";

export function getFolderData(filesList, pathList) {
    const currPath = pathList[0];
    const currFolder = filesList && filesList.find(media => {
        return media.type === MEDIA_TYPES.FOLDER && media.name === currPath
    });
    if (!currFolder) {
        return [];
    }
    const pathRightPart = pathList.slice(1);
    return pathRightPart.length
        ? getFolderData(currFolder.children, pathRightPart)
        : currFolder.children
}
