import {_urlPartner, request} from "../api";
import {PARTNER_CONSTS} from "../constants";
import {notificationSuccess} from "../../components/uiElements/Notifications";

export const GetPartnersData = () => {
    const requestData = {
        url: _urlPartner,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type: PARTNER_CONSTS.SET_PARTNERS_DATA,
                    payload: data
                });
            })
    }
};

export const UpdatePartnersData = (data) => {
    const requestData = {
        url: `${_urlPartner}`,
        token: true,
        method: "PATCH",
        data,
    };
    return dispatch => {
        return request(requestData)
            .then(res => {
                dispatch({
                    type: PARTNER_CONSTS.SET_PARTNERS_DATA,
                    payload: res.data
                });
                notificationSuccess({
                    description: "Տվյալները հաջողությամբ փոփոխվեց!"
                });
                return res.data
            })
    }
};
