import { SERVICES_CONSTS } from "../constants";

export const initialState = {
    servicesData: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case SERVICES_CONSTS.SET_SERVICES_DATA:
            return {
                ...state,
                servicesData: action.payload ,
            };
        case SERVICES_CONSTS.DELETE_SERVICE_ITEM:
            return {
                ...state,
                servicesData: {
                    ...state.servicesData,
                    services: state.servicesData.services.filter(item => item.id !== action.payload)
                } ,
            };

        default:
            return state;
    }
}
