import {BLOG_CONSTS, AUTH_CONSTS} from "../constants";

export const initialState = {
    blogData: null,
    blogList: {
        itemsList: [],
        hasMore: false,
        category: undefined,
    },
    productById: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case BLOG_CONSTS.SET_BLOG_PAGE:
            return {
                ...state,
                blogData: action.payload,
            };
        case BLOG_CONSTS.SET_BLOG_LIST:
            return {
                ...state,
                blogList: {
                    itemsList: action.payload.reset ? action.payload.data : [...state.blogList.itemsList, ...action.payload.data],
                    category: action.payload.category,
                    hasMore: action.payload.hasMore
                }
            };
        case BLOG_CONSTS.GET_BLOG_BY_ID:
            return {
                ...state,
                productById: action.payload
            };
        // case BLOG_CONSTS.ADD_BLOG:
        //     return {
        //         ...state,
        //         blogList: {
        //             ...state.blogList,
        //             itemsList: [action.payload, ...state.blogList.itemsList],
        //             count: state.blogList.count + 1
        //         }
        //     };
        case BLOG_CONSTS.UPDATE_BLOG_ITEM:
            return {
                ...state,
                blogList: {
                    ...state.blogList,
                    itemsList: state?.blogList?.itemsList?.map(product => product.id === action.payload.id ?
                        action.payload : product)
                }
            };
        case BLOG_CONSTS.DELETE_BLOG_ITEM:
            return {
                ...state,
                blogList: {
                    ...state.blogList,
                    itemsList: state.blogList.itemsList.filter(product => product.id !== action.payload),
                    count: state.blogList.count - 1
                }
            };
        case AUTH_CONSTS.LOG_OUT:
            return initialState;
        default:
            return state;
    }
}
