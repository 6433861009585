import { ABOUT_CONSTS } from "../constants";

export const initialState = {
    about: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case ABOUT_CONSTS.SET_ABOUT_DATA:
            return {
                ...state,
                about: action.payload ,
            };

        default:
            return state;
    }
}
