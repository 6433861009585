// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
//import styles
import '../../assets/styles/containerStyles/about.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues, getTranslationData, initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {UpdateServicesItemData} from "../../redux/actions";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {history} from "../../configs/history";
import {validateTranslations} from "../../utils/validateTranslations";
import {Editor} from "../../components/uiElements/Editor";

class AddServicesBlock extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'title',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaMain: undefined,
            },
            errors: {},
            errorsTabs: [],
            servicesId : props.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.addEditServicesBlocks = this.addEditServicesBlocks.bind(this);

    }


    getMedia(mediaArray, type) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData[type]?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set([type], mediaArray[0]?.id)
                : this.updatedDataMap.delete([type]);
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                [type]: mediaArray[0],
            },
            errors: {
                ...errors,
                [type]: false
            }
        })
    }

    deleteMedia() {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain ?
                this.updatedDataMap.set("mediaMain", null) :
                this.updatedDataMap.delete("mediaMain");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: null
            }
        })
    }

    async addEditServicesBlocks() {
        const {servicesData} = this.props;
        const {servicesId} = this.state;
        let slug = this.props.computedMatch.params.slug
        console.log(this.props.computedMatch.params.slug,'props')
        await this.setState({
            requestLoading: true
        });
        const {mediaMain,translations} = this.state.fieldsData;
        const translationRequiredFields = ['title'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (!mediaMain) {
            errors.mediaMain = true;
            result = false;
        }

        if (result) {
            const trData = getTranslationData(translations);
            this.state.fieldsData.translations = trData
            const service = servicesData.find(item => item.id === servicesId)
            let data = {
                ...this.state.fieldsData,
                mediaMain:this.state.fieldsData.mediaMain.id
            }
            let services = service[slug]?.map(item => {
                return{
                    translations:item.translations,
                    mediaMain:item.mediaMain.id
                }
            })
            let reqData = undefined
            if(slug === 'blocks'){
                reqData = {
                    blocks:[...services,data]
                }
            }else {
                reqData = {
                    features:[...services,data]
                }
            }

                this.props.UpdateServicesItemData(servicesId,reqData).then(() => {
                    this.updatedDataMap.clear();
                }).catch(() => {
                }).finally(() => {
                    this.setState({requestLoading: false});
                    history.push('/services')
                })
            }else {
                this.setState({
                    requestLoading: false,
                    errors: errors,
                    errorsTabs: validationTr.errorsTabs
                })
            }
    }

    render() {
        const {fieldsData, errors, mediaModalOpen,languageTab,errorsTabs, requestLoading, isEditing} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        return <PageWrapper>
            <section className="slider-add-edit">

                <div className="row">
                    <div>
                        <div className="top-side">
                            <label>Նկար <span className={'required-badge'}>*</span></label>
                            <LinkButton title="Ընտրել մեդիադարանից"
                                        className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                        cb={this.toggleMediaModal}
                            />
                        </div>
                        {fieldsData?.mediaMain && <div className={'main-media'}>
                            <MediaFileCard item={fieldsData?.mediaMain}
                                           customDelete={this.deleteMedia}
                            />
                        </div>}
                    </div>
                    <div>
                    </div>

                </div>

                <div className="info-wrapper">
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Անվանում"
                                placeholder="Անվանում"
                                name="title"
                                value={currentData.title}
                                initValue={initData.title}
                                error={errors['title' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                </div>
                <div className="editor-wrapper">
                    <label>Նկարագրություն<span className={'required-badge'}>*</span></label>
                    <Editor value={currentData?.description}
                            name={'description'}
                            initValue={initData.description}
                            error={errors['description' + languageTab]}
                            onChange={this.getTranslationValues}/>
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditServicesBlocks}/>
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={(mediaArray) => this.getMedia(mediaArray, 'mediaMain')}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    UpdateServicesItemData
};

export default connect(mapStateToProps, mapDispatchToProps)(AddServicesBlock)
