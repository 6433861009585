// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
//import styles
import '../../assets/styles/containerStyles/about.scss';

// Import components
import PageWrapper from '../../components/pageContentViews/pageWrapper'
import MediaSelectorModal from "../../components/media/MediaSelectorModal";
import {InputGroup} from "../../components/uiElements/inputGroup";
import {LinkButton} from "../../components/buttons/buttons";
import {LanguageTabs} from "../../components/uiElements/Tabs";
import {MediaFileCard} from "../../components/media/MediaFileCard";

// Import utils
import {
    changeStateField,
    getInputValues,
    getPatchReqData,
    getTranslatableInputValues,
    getTranslationData,
    initTranslations,
    toggleStateField
} from "../../utils/helperFunctions";
import {mapStateToProps} from "../../redux/mapStateToProps";
import {UpdateMembersItemData, CreateMemberItem} from "../../redux/actions";
import {validateTranslations} from "../../utils/validateTranslations";
import {ACCEPT_IMAGE_TYPES} from "../../constants/acceptedTypes";
import {history} from "../../configs/history";
import {Editor} from "../../components/uiElements/Editor";
import isURL from "validator/es/lib/isURL";

class AddEditMember extends Component {
    constructor(props) {
        super(props);
        this.translationsFields = [
            'name',
            'position',
            'description',
        ];
        this.state = {
            fieldsData: {
                translations: initTranslations(this.translationsFields, props.activeLanguages),
                mediaMain: undefined,
            },
            errors: {},
            errorsTabs: [],
            isEditing: !!props?.id,
            languageTab: props.mainLanguage,
            requestLoading: false,
        };
        this.updatedDataMap = new Map();
        this.currentData = null;
        this.toggleMediaModal = toggleStateField.bind(this, 'mediaModalOpen');
        this.changeLanguageTab = changeStateField.bind(this, 'languageTab');
        this.getInputValues = getInputValues.bind(this);
        this.getPatchReqData = getPatchReqData.bind(this);
        this.getTranslationValues = getTranslatableInputValues.bind(this);
        this.getMedia = this.getMedia.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.addEditMember = this.addEditMember.bind(this);

    }

    async componentDidMount() {
        const {membersData, id} = this.props;
        const {isEditing, fieldsData} = this.state;

        this.customPagesOptions = [];
        Array.isArray(this.props.customPagesList) && this.props.customPagesList.forEach(item => {
            const name = item?.translations?.find(tr => tr.languageId === this.props.mainLanguage)?.name || '';
            this.customPagesOptions.push({
                name: name,
                id: item?.id,
            })
        });
        if (isEditing && !id) {
            history.push('/members');
            return;
        }

        if (isEditing) {
            const translationsData = {};
            const editingData = membersData?.members.find(member => member.id === this.props.id);
            if (editingData) {
                Object.keys(fieldsData.translations).forEach(key => {
                    const trItem = editingData.translations.find(item => item.languageId === key);
                    translationsData[key] = {
                        ...fieldsData.translations[key],
                        name: trItem?.name || '',
                        position: trItem?.position || '',
                        description: trItem?.description || '',
                    };
                });
            }
            const initStateData = {
                translations: translationsData,
                mediaMain: editingData?.mediaMain || '',
            };
            this.currentData = JSON.parse(JSON.stringify(initStateData));
            this.setState({
                fieldsData: initStateData,
            });
        }
    }

    getMedia(mediaArray) {
        const {fieldsData, errors, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain?.id !== mediaArray[0]?.id
                ? this.updatedDataMap.set('mediaMain', mediaArray[0]?.id)
                : this.updatedDataMap.delete('mediaMain');
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: mediaArray[0],
            },
            errors: {
                ...errors,
                mediaMain: false
            }
        })
    }

    deleteMedia() {
        const {fieldsData, isEditing} = this.state;
        if (isEditing) {
            this.currentData?.mediaMain ?
                this.updatedDataMap.set("mediaMain", null) :
                this.updatedDataMap.delete("mediaMain");
        }
        this.setState({
            fieldsData: {
                ...fieldsData,
                mediaMain: null
            }
        })
    }

    async addEditMember() {
        const {id} = this.props;
        await this.setState({
            requestLoading: true
        });
        const {translations, mediaMain} = this.state.fieldsData;
        const translationRequiredFields = ['name', 'position'];
        const validationTr = validateTranslations(translations, translationRequiredFields);
        let result = validationTr.result;
        const errors = {...validationTr.errors};

        if (result) {
            if (!this.state.isEditing) {
                const reqData = {
                    mediaMain:mediaMain ? mediaMain.id : null,
                    translations: getTranslationData(translations)
                };

                this.props.CreateMemberItem(reqData).finally(() => {
                    this.setState({requestLoading: false});
                })
            } else {
                const trData = getTranslationData(translations);
                const reqData = this.getPatchReqData(trData);
                this.props.UpdateMembersItemData(id, reqData)
                    .finally(() => {
                        this.setState({requestLoading: false});
                    });
            }
        } else {
            this.setState({
                requestLoading: false,
                errors: errors,
                errorsTabs: validationTr.errorsTabs
            })
        }
    }

    render() {
        const {fieldsData, errors, languageTab, mediaModalOpen, requestLoading, isEditing, errorsTabs} = this.state;
        const initData = this.currentData?.translations[languageTab] || {};
        const currentData = fieldsData.translations[languageTab] || {};

        return <PageWrapper pageTitle={!isEditing ? 'Ավելացնել Թիմակից' : 'Փոփոխել Թիմակին'}>
            <section className="slider-add-edit">

                <div className="top-side">
                    <label>Նկար <span className={'required-badge'}>*</span></label>
                    <LinkButton title="Ընտրել մեդիադարանից"
                                className={`media-select-btn ${errors.mediaMain ? 'media-select-error' : ''}`}
                                cb={this.toggleMediaModal}
                    />
                </div>
                {fieldsData?.mediaMain && <div className={'main-media'}>
                    <MediaFileCard item={fieldsData?.mediaMain}
                                   customDelete={this.deleteMedia}
                    />
                </div>}


                <div className="info-wrapper">
                    <LanguageTabs changeLanguageTab={this.changeLanguageTab}
                                  errorsTabs={errorsTabs}
                                  activeTab={languageTab}/>
                    <InputGroup inputType="input"
                                type="text"
                                label="Անուն"
                                placeholder="Անուն"
                                name="name"
                                value={currentData.name}
                                initValue={initData.name}
                                error={errors['name' + languageTab]}
                                maxLength={100}
                                required={true}
                                onChange={this.getTranslationValues}/>
                     <InputGroup inputType="input"
                                          type="text"
                                          label="Պաշտոն"
                                          placeholder="Պաշտոն"
                                          name="position"
                                          value={currentData.position}
                                          initValue={initData.position}
                                          error={errors['position' + languageTab]}
                                          maxLength={1000}
                                          required={true}
                                          onChange={this.getTranslationValues}/>
                     {/*<InputGroup inputType="textarea"*/}
                     {/*                     type="text"*/}
                     {/*                     label="Նկարագրություն"*/}
                     {/*                     placeholder="Նկարագրություն"*/}
                     {/*                     name="description"*/}
                     {/*                     value={currentData.description}*/}
                     {/*                     initValue={initData.description}*/}
                     {/*                     error={errors['description' + languageTab]}*/}
                     {/*                     required={false}*/}
                     {/*                     onChange={this.getTranslationValues}/>*/}
                </div>

                <div className="flex-wrapper-right">
                    <LinkButton title={!isEditing ? 'Ավելացնել' : 'Փոփոխել'}
                                loading={requestLoading}
                                disabled={isEditing && !this.updatedDataMap.size}
                                cb={this.addEditMember}/>
                </div>

            </section>
            <MediaSelectorModal
                isOpen={!!mediaModalOpen}
                acceptTypes={ACCEPT_IMAGE_TYPES}
                getMedia={this.getMedia}
                closeModal={this.toggleMediaModal}
            />
        </PageWrapper>
    }
}

const mapDispatchToProps = {
    CreateMemberItem,
    UpdateMembersItemData
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEditMember)
