import {_urlCallModel, request} from "../api";
import {RESERVATION_CONST} from "../constants";


export const getCallData = () => {

    const requestData = {
        url: _urlCallModel,
        token: true,
        method: "GET",
    };

    return dispatch => {
        return request(requestData)
            .then(({ data }) => {
                dispatch({
                    type:RESERVATION_CONST.GET_CALL_DATA,
                    payload: data
                });
            })
    }
}

export const DeleteCallDataById = id => {

    const requestData = {
        url: `${_urlCallModel}/${id}`,
        token: true,
        method: "DELETE",
    };

    return dispatch => {
        return request(requestData)
            .then(() => {
                dispatch({
                    type: RESERVATION_CONST.DELETE_CALL_DATA,
                    payload: id
                });
            })
    }
};

