import { PARTNER_CONSTS} from "../constants";

export const initialState = {
    partnersData: null
};

export default (state = initialState, action) => {
    switch ( action.type ) {
        case PARTNER_CONSTS.SET_PARTNERS_DATA:
            return {
                ...state,
                partnersData: action.payload ,
            };
        default:
            return state;
    }
}
