// Import packages
import React, {Component} from "react";
import {connect} from "react-redux";
import Nestable from "react-nestable";

// Import components
import {NestableItem} from "../../components/cards/nestableItem";
import PageWrapper from "../../components/pageContentViews/pageWrapper";
import PageHeader from "../../components/pageContentViews/pageHeader";

// Import utils
import {mapStateToProps} from "../../redux/mapStateToProps";
import {deleteItem} from "../../utils/helperFunctions";
import {DeleteBlogItem, GetBlogList,} from "../../redux/actions";
import InfiniteScroll from "react-infinite-scroller";
import {LIMIT_GET_BLOG} from "../../constants/constLimitCounts";


class Blogs extends Component {
    constructor(props) {
        super(props);
        this.deleteItem = deleteItem.bind(this, props.DeleteBlogItem, 'Բլոգը');
        this.onPositionChange = this.onPositionChange.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    componentDidMount() {
        this.props.GetBlogList({
            offset:0,
            limit: LIMIT_GET_BLOG,
        });
    }

    loadMoreItems(){
        const { hasMore,itemsList } = this.props.blogList;
        hasMore && this.props.GetBlogList({
            reset: false,
            offset:itemsList.length,
            limit: LIMIT_GET_BLOG,
        });
    }

    getNestableItems(list, parent = null) {
        const items = [];
        const {mainLanguage} = this.props;
        // eslint-disable-next-line no-unused-expressions
        !!list?.itemsList.length && list?.itemsList.forEach((item, index) => {
            const trData = item?.translations?.find(tr => tr.languageId === mainLanguage);
            const title = trData?.title || '';

            items.push({
                id: item.id,
                index: index,
                title: title,
                withImage: true,
                isHidden: item.isHidden,
                path: item?.mediaMain?.path,
                link: {
                    pathname: `/blog/edit/${item.id}`,
                },
                deleteCb: () => this.deleteItem(item.id),
                children: []
            })
        });
        return items;
    }

    async onPositionChange(items, item) {
        const movedSliderId = item.id;
        const {blogList} = this.props;
        const positionedItems = items.map((item, index) => {
            return {id: item.id, position: index}
        });
        if (~blogList.findIndex(m => m.id === movedSliderId) &&
            ~positionedItems.findIndex(m => m.id === movedSliderId)) {
            // first level menu was re-positioned in first level
            this.props.GetBlogList();
        }
    }

    render() {
        let {blogList} = this.props;
        let items = this.getNestableItems(blogList);
        return <PageWrapper pageTitle={'Բլոգ'}>
            <PageHeader linkTitle={"Ավելացնել"}
                        addingLink={"/blog/add"}/>
            <InfiniteScroll
                hasMore={blogList?.hasMore}
                loadMore={this.loadMoreItems}
                pageStart={0}
                className={'groupWorkoutList'}
                useWindow={true}
                initialLoad={false}>
                {!!blogList?.itemsList?.length ?
                    <Nestable
                        items={items}
                        confirmChange={()=>false}
                        maxDepth={1}
                        handler={()=>{}}
                        renderItem={NestableItem}
                    />
                    : <div className={'empty-view'}>
                        {/*<img src={defaultEmptyView}/>*/}
                    </div>}
            </InfiniteScroll>
        </PageWrapper>

    }
}

const mapDispatchToProps = {
    GetBlogList,
    DeleteBlogItem
};

export default connect(mapStateToProps, mapDispatchToProps)(Blogs);