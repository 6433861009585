// Import packages
import React, {Component} from "react";

//import assets
import '../../assets/styles/media/add-folder-card.scss';
import folderIcon from '../../assets/images/file-icons/folder.svg';

// Import components
import {InputGroup} from "../uiElements/inputGroup";
import {LinkButton} from "../buttons/buttons";

// Import utils

class AddFolderCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            folderNameBroken: false,
            folderNameExist: false,
        };
        this.getInputValues = this.getInputValues.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.addFolder = this.addFolder.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps?.clearCardData !== this.props?.clearCardData) {
            this.setState({
                folderNameBroken: false,
                folderNameExist: false,
                name: ''
            })
        }
    }

    getInputValues({name, value,}) {
        this.setState({
            [name]: value,
            folderNameBroken: false,
            folderNameExist: false,
        });
    }


    async addFolder() {
        const folderName = this.state.name;
        if (/^[-a-zA-Zա-ֆԱ-Ֆа-яА-Я0-9]+$/.test(folderName)) {
            this.props.addFolder(folderName).then(() => {
                this.closeModal()
            }).catch((error) => {
                //     console.log('vasya')
                if (error && error.response && error.response.status === 409) {
                    this.setState({
                        folderNameExist: true
                    })
                }
            });
        } else {
            this.setState({
                folderNameBroken: true
            })
        }
    }

    closeModal() {
        this.setState({
            folderNameBroken: false,
            folderNameExist: false,
            name: ''
        });
        this.props.closeModal()
    }

    render() {
        const {requestLoading} = this.props;
        const {name, folderNameBroken, folderNameExist} = this.state;
        return <div>
            <div className={'add-folder-card'}>
                <div className={"form-wrapper"}>
                    <InputGroup inputType={"input"}
                                type={"text"}
                                placeholder={"Անուն"}
                                name={"name"}
                                maxLength={50}
                                required={true}
                                error={folderNameBroken || folderNameExist}
                                value={name}
                                onChange={this.getInputValues}>
                        <img className={"placeholder-img"} src={folderIcon}
                             alt=""/>
                    </InputGroup>
                    <div className={'folder-name-broken'}>
                        {folderNameBroken &&
                        <span>Միայն տառեր , թվեր և -</span>
                        }
                        {folderNameExist &&
                        <span>Այս անունով թղթապանակ արդեն գոյություն ունի</span>
                        }
                    </div>
                </div>
                <div className="buttons-wrapper">
                    <LinkButton title="Չեղարկել"
                                loading={requestLoading}
                                className={"bg-white"}
                                cb={this.closeModal}/>
                    <LinkButton title="Ավելացնել"
                                loading={requestLoading}
                                className={"bg-green"}
                                cb={this.addFolder}/>
                </div>
            </div>
        </div>
    }
}


export default AddFolderCard;
